import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import * as Constants from "../Constants";
import Loading from "./Loading";
import "../App.css";
import axios from "axios";
import "../CSS/moviesessions.css";
import { Modal, Button, Form, ThemeProvider } from "react-bootstrap";
import swal from "sweetalert";

class MovieSession extends Component {
  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: localStorage.getItem('lat') && localStorage.getItem('lat') !== "null" ? localStorage.getItem('lat') : '',
    lng: localStorage.getItem('lng') && localStorage.getItem('lng') !== "null" ? localStorage.getItem('lng') : '',
    date: "",
    lang: "ALL",
    format: "ALL",
    price: "ALL",
    time: "ALL",
    cinetype: "ALL",
    special: "ALL",
    hc: "ALL",
    output: {},
    lngs: [],
    cinemas: [],
    days: [],
    selectedDate: "",
    selectedDay: "",
    tnc: "",
  };

  componentDidMount() {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("city", this.props.match.params.city); //append the values with key, value pair
    formDataNS.append("mid", this.state.amcode);
    formDataNS.append("lat", this.state.lat);
    formDataNS.append("lng", this.state.lng);
    formDataNS.append("date", this.state.date);
    // formDataNS.append('date', '2020-12-09');
    formDataNS.append("userid", localStorage.getItem("userid"));
    formDataNS.append("lang", this.state.lang);
    formDataNS.append("format", this.state.format);
    formDataNS.append("price", this.state.price);
    formDataNS.append("time", this.state.time);
    formDataNS.append("cinetype", this.state.cinetype);
    formDataNS.append("special", this.state.special);
    formDataNS.append("hc", this.state.date);
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("did", Constants.did);

    const movieSessionRequest = async () => {
      try {
        this.setState({
          loading: 1,
        });
        // const resp =
        await axios
          .post(Constants.api + "/api/content/msessionsnewfilters", formDataNS)
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              this.setState({
                output: resp.data.output,
              });
              let lngsArray = [];
              Object.keys(resp.data.output.lngs).forEach(function (key) {
                lngsArray.push(resp.data.output.lngs[key]);
              });

              let daysArray = [];
              Object.keys(resp.data.output.dys).forEach(function (key) {
                daysArray.push(resp.data.output.dys[key]);
              });

              let sDay = "";
              let sD = "";
              Object.keys(daysArray).forEach(function (item, index) {
                if (index == 0) {
                  console.log(daysArray[index].wd);
                  sDay = daysArray[index].wd;
                  sD = daysArray[index].d;
                  return;
                }
              });

              let cinemasArray = [];
              Object.keys(resp.data.output.cinemas).forEach(function (key) {
                cinemasArray.push(resp.data.output.cinemas[key]);
              });

              this.setState({
                lngs: lngsArray,
                cinemas: cinemasArray,
                days: daysArray,
                selectedDay: sDay,
                selectedDate: sD,
                tnc: resp.data.output.btnc,
              });

              if (this.state.tnc != "") {
                swal(
                  "MovieTime",
                  this.state.tnc
                    .replaceAll("<br/>", "\n")
                    .replaceAll("</p>", ""),
                  ""
                );
              }
            } else {
              console.log(resp.data.msg);
              alert(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    movieSessionRequest();
  }

  render() {
    if (this.state.loading == 1) {
      return (
        <>
          <Loading />
        </>
      );
    }

    const selectDate = (dateSelected, daySelected, ddSelected) => {
      let formDataDS = new FormData(); //formdata object
      formDataDS.append("city", localStorage.getItem("selectedcity")); //append the values with key, value pair
      formDataDS.append("mid", this.state.amcode);
      formDataDS.append("lat", this.state.lat);
      formDataDS.append("lng", this.state.lng);
      formDataDS.append("date", dateSelected);
      // formDataDS.append('date', '2020-12-09');
      formDataDS.append("userid", localStorage.getItem("userid"));
      formDataDS.append("lang", this.state.lang);
      formDataDS.append("format", this.state.format);
      formDataDS.append("price", this.state.price);
      formDataDS.append("time", this.state.time);
      formDataDS.append("cinetype", this.state.cinetype);
      formDataDS.append("special", this.state.special);
      formDataDS.append("hc", this.state.date);
      formDataDS.append("av", Constants.av);
      formDataDS.append("pt", Constants.pt);
      formDataDS.append("did", Constants.did);
      try {
        this.setState({
          loading: 1,
        });
        // const resp =
        axios
          .post(Constants.api + "/api/content/msessionsnewfilters/", formDataDS)
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let cinemasArray = [];
              Object.keys(resp.data.output.cinemas).forEach(function (key) {
                cinemasArray.push(resp.data.output.cinemas[key]);
              });

              this.setState({
                cinemas: cinemasArray,
              });

              console.log(this.state.cinemas);
              var element = document.getElementById("otherDateFilter");
              element.classList.remove("active");
              this.setState({ selectedDay: daySelected });
              this.setState({ selectedDate: ddSelected });
            } else {
              console.log(resp.data.msg);
              alert(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const trailerShow = (title, url) => {
      var video_id = url.split("v=")[1];
      var ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
      let videoUrl = "https://www.youtube.com/embed/" + video_id;
      this.setState({ setShow: 1, movietitle: title, videoUrl: videoUrl });
    };
    const closetrailer = () => {
      this.setState({ setShow: 0 });
    };

    const languageRender = this.state.lngs.map(function (item, i) {
      return <span>{item}</span>;
    });

    const daysRender2 = this.state.days.map(function (item, i) {
      return (
        <>
          <div
            class="date-item carousel-cell"
            onClick={() => selectDate(item.dt, item.wd, item.d)}
          >
            <label class="day" for="day">
              {item.wd}
            </label>
            <label class="date" for="date">
              {item.d}
            </label>
          </div>
        </>
      );
    });

    const cinemaRender = this.state.cinemas.map(function (mcinema, i) {
      // '/seatlayout/' + mcinema.cn.toString().toLowerCase().replaceAll(' ', '-') + '/' + this.state.mname + '?mcode=' + show.mc
      ///seatlayout/pvr-sapphire-pacific-d21-dwarka/suraj-pe-mangal-bhari?mcode=HO00017429&sessionid=756&cinemacode=PASA&screenId=5&bundleAllowed=true
      return (
        <>
          <div class="cinema-holder" key={i}>
            <div class="cinema-title" style={{ cursor: "pointer" }}>
              <h4 class="title">{mcinema.cn}</h4>
              <span class="extras _clr_dark">
                <i class="ion-android-navigate marginright-sm"></i>
                {Math.round(mcinema.dst / 1000)} km away
              </span>
            </div>

            {mcinema.childs.map((cinema, j) => {
              return (
                <>
                  {/* {cinema.ccid} */}
                  {cinema.sws.map((sws, k) => {
                    return (
                      <>
                        <div class="collpased active">
                          <div class="cinema-types">
                            <div class="type type-upper">
                              <div class="type-holder">
                                <h4 class="type-title">{sws.lng}</h4>
                                <ul class="type-time-slots">
                                  {sws.s.map((show, l) => {
                                    let showClass = "slot ";
                                    if (show.ss == 1) {
                                      showClass += "text-success";
                                    } else if (show.ss == 2) {
                                      showClass += "text-warning";
                                    } else if (show.ss == 3) {
                                      showClass += "text-danger";
                                    } else {
                                      showClass += "text-default";
                                    }
                                    return (
                                      <>
                                        <span
                                          class="popover-holder pricing-popover full-width"
                                          placement="top"
                                        >
                                          <div
                                            role="popover"
                                            class="popover fade"
                                          >
                                            <div
                                              class="virtual-area"
                                              hidden=""
                                            ></div>
                                            <div class="arrow"></div>
                                            <h3
                                              class="popover-title"
                                              hidden=""
                                            ></h3>
                                            <div class="popover-content">
                                              <div class=""></div>
                                            </div>
                                          </div>
                                        </span>
                                        <li>
                                          {show.ss == 0 || show.ss == 3 ? (
                                            <a
                                              disabled="disabled"
                                              class={showClass}
                                            >
                                              {show.st}
                                            </a>
                                          ) : (
                                            <a
                                              href={
                                                "/seatlayout/" +
                                                mcinema.cn
                                                  .toString()
                                                  .toLowerCase()
                                                  .replaceAll(" ", "-") +
                                                "/movie?mcode=" +
                                                show.mc +
                                                "&sessionid=" +
                                                show.sid +
                                                "&cinemacode=" +
                                                cinema.ccid +
                                                "&screenId=" +
                                                show.sn +
                                                "&bundleAllowed=false"
                                              }
                                              class={showClass}
                                            >
                                              {show.st}
                                            </a>
                                          )}
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })}
          </div>
        </>
      );
    });

    const dateHover = () => {
      var element = document.getElementById("otherDateFilter");
      element.classList.add("active");
    };

    const dateHide = () => {
      var element = document.getElementById("otherDateFilter");
      element.classList.remove("active");
    };

    return (
      <>
        <Header />
        <div className="top-header text-center">
          <div className="col-sm-12">
            <div className="row">
              <img src={require("../assets/safety.png").default} />
            </div>
          </div>
        </div>
        {/* Seefety Strip */}
        <section class="moviesesion_section">
          <div class="top-header">
            <div class="row">
              {/* mobile view sticky right */}
              <div class="col-sm-3 col-xs-12 visible-xs">
                <div class="sticky-right _w_100 _m_m_">
                  <div class="booking_summary movie_det_ _35px">
                    <h3 class="_summary_txt">{this.state.output.nm}</h3>
                    <img
                      class="img-responsive"
                      src={this.state.output.mih}
                      alt=""
                    />
                    <div class="_summary_details ">
                      <div class="_item_list">
                        <p class="_name__">Movie Name</p>
                        <h4 class="movie_name__">
                          {this.state.output.nm} {this.state.output.cert}{" "}
                        </h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Language</p>
                        <h4 class="movie_name__"> {languageRender}</h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Genre</p>
                        <h4 class="movie_name__"> {this.state.output.gnr}</h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Duration</p>
                        <h4 class="movie_name__"> {this.state.output.len}</h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Release Date</p>
                        <h4 class="movie_name__"> {this.state.output.od}</h4>
                      </div>

                      {this.state.output.vdo ? (
                        <i
                          class="fa fa-play _floa_right"
                          onClick={() =>
                            trailerShow(
                              this.state.output.nm,
                              this.state.output.vdo
                            )
                          }
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}

                      {/* <div class="grid__ text-right">
                                <div class="_liked_">
                                    <i aria-hidden="true" class="fa fa-share-alt" style={{ marginRight: "5px" }}></i>
                                    <i class="fa fa-heart-o" onclick="myFunction(this)"><span class="_like_clr">Add to favourite</span></i>
                                    <div class="text-white text-right" style={{ fontSize: "13px" }}>7.3 K</div>
                                </div>
                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* mobile view sticky right */}
              <div class="col-sm-9 col-xs-12">
                {this.state.output.p ? (
                  <div class="synosis-cont">{this.state.output.p}</div>
                ) : (
                  ""
                )}

                <div class="tab-content">
                  <div id="showtimes" class="tab-pane fade in active">
                    <div class="col-sm-12 col-xs-12">
                      <div class="card-block">
                        <div class="m-info-filter-holder" onClick={dateHover}>
                          <div class="m-filters">
                            <div class="date-filter">
                              <div class="filter-group datepicker">
                                <label>{this.state.selectedDay}</label>
                                <div class="_date_">
                                  {this.state.selectedDate}
                                </div>
                                <div class="date-nav">
                                  <div class="nav nav-left no-event">
                                    <i
                                      class="fa fa-chevron-left"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div class="nav nav-right no-event">
                                    <i
                                      class="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="other-filter" style={{ width: "100%" }}>
                              <div
                                class="main-carousel latest-dates"
                                id="otherDateFilter"
                              >
                                {daysRender2}
                              </div>
                              <div className="seat_aval_custome">
                                <div class="right labels">
                                  <ul class="abbr-labels fx--jc-fe">
                                    <li>
                                      <span class="text-success">
                                        Available
                                      </span>
                                    </li>
                                    <li>
                                      <span class="text-warning">
                                        Filling Fast
                                      </span>
                                    </li>
                                    <li>
                                      <span class="text-danger">Sold Out</span>
                                    </li>
                                    <li>
                                      <span class="text-default">Lapsed</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div class="m-cinema-search">
                                    <div class="cinema-search-inner">
                                        <div class="left search">
                                            <div class="inputtext-group _pos_rel">
                                                <span class="clear"><i class="fa fa-close"></i></span>
                                                <input class="cinema-search-inputtext" name="searchedCinema" placeholder="Search Cinemas...." type="text" />
                                                <i class="fa fa-search _search_cinemas"></i>
                                            </div>
                                        </div>
                                        <div class="right labels">
                                            <ul class="abbr-labels fx--jc-fe">
                                                <li><span class="text-success">Available</span></li>
                                                <li><span class="text-warning">Filling Fast</span></li>
                                                <li><span class="text-danger">Sold Out</span></li>
                                                <li><span class="text-default">Lapsed</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                        <div class="m-cinema-listing">
                          <div class="cinema-listing-inner">{cinemaRender}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="synosis" class="tab-pane ">
                    <div class="synosis-cont">{this.state.output.p}</div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 col-xs-12 hidden-xs">
                <div class="sticky-right _w_100">
                  <div class="booking_summary movie_det_ _35px">
                    <h3 class="_summary_txt">{this.state.output.nm}</h3>
                    <img
                      class="img-responsive"
                      src={this.state.output.mih}
                      alt=""
                    />
                    <div class="_summary_details ">
                      <div class="_item_list">
                        <p class="_name__">Movie Name</p>
                        <h4 class="movie_name__">
                          {this.state.output.nm} {this.state.output.cert}{" "}
                        </h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Language</p>
                        <h4 class="movie_name__"> {languageRender}</h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Genre</p>
                        <h4 class="movie_name__"> {this.state.output.gnr}</h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Duration</p>
                        <h4 class="movie_name__"> {this.state.output.len}</h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Release Date</p>
                        <h4 class="movie_name__"> {this.state.output.od}</h4>
                      </div>

                      {this.state.output.vdo ? (
                        <i
                          class="fa fa-play _floa_right"
                          onClick={() =>
                            trailerShow(
                              this.state.output.nm,
                              this.state.output.vdo
                            )
                          }
                          aria-hidden="true"
                        ></i>
                      ) : (
                        ""
                      )}

                      {/* <div class="grid__ text-right">
                                <div class="_liked_">
                                    <i aria-hidden="true" class="fa fa-share-alt" style={{ marginRight: "5px" }}></i>
                                    <i class="fa fa-heart-o" onclick="myFunction(this)"><span class="_like_clr">Add to favourite</span></i>
                                    <div class="text-white text-right" style={{ fontSize: "13px" }}>7.3 K</div>
                                </div>
                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Modal show={this.state.setShow} animation={false}>
          <Modal.Header closeButton onClick={closetrailer}>
            <Modal.Title>{this.state.movietitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <iframe
                width="100%"
                height="400"
                src={this.state.videoUrl}
              ></iframe>
            </>
          </Modal.Body>
          <Modal.Footer>
            <img
              src={require("../assets/logo.png").default}
              alt=""
              width="200"
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MovieSession;

import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import Loading from './Loading';
import { Modal, Button, Form } from "react-bootstrap";

class Cinema extends Component {

  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: '',
    lang: 'ALL',
    format: 'ALL',
    price: 'ALL',
    time: 'ALL',
    cinetype: 'ALL',
    special: 'ALL',
    hc: 'ALL',
    output: {},
    lngs: [],
    cinemas: [],
    days: [],
    search: '',
  }
  componentDidMount() {
    if (!localStorage.getItem('userid'))
      localStorage.setItem('userid', 0);
    let formData = new FormData();    //formdata object
    formData.append('searchtxt', this.state.search);   //append the values with key, value pair
    formData.append('av', Constants.av);
    formData.append('pt', Constants.pt);
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('lat', '');
    formData.append('lng', '');
    formData.append('city', localStorage.getItem('selectedcity'));
    const sendGetRequest = async () => {

      try {
        const resp = await axios.post(Constants.api + '/api/content/alltheater', formData).then(resp => {
          //  casetype array   

          if (resp.data.result == 'success') {
            this.setState(
              {
                loading: 0
              }
            );

            this.setState({ mapUrl: 'https://www.google.com/maps/embed/v1/place?q=' + resp.data.output.c[0].lat + ',' + resp.data.output.c[0].lang + '&key=AIzaSyCar-wmGPP_TMi_Phi9HoIosnGf3N8ioQ8' })

            let cinemasArray = []
            Object.keys(resp.data.output.c).forEach(function (key) {
              cinemasArray.push(resp.data.output.c[key])
            });

            this.setState({
              cinemas: cinemasArray,
            })
            console.log(this.state.cinemas);
          } else {
            console.log(resp.data.msg);
            this.setState(
              {
                loading: 1
              }
            );
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    sendGetRequest();
  }


  render() {
    const changemap = (lat, long) => {
      this.setState({ mapUrl: 'https://www.google.com/maps/embed/v1/place?q=' + lat + ',' + long + '&key=AIzaSyCar-wmGPP_TMi_Phi9HoIosnGf3N8ioQ8' })
    }
    const cinemaRender = this.state.cinemas.map(function (item, i) {
      let sessionUrl = "/cinemasessions/" + localStorage.getItem('selectedcity') + "/" + item.n + "/" + item.cId;
      return (
        <>
          <div class="cinema-box" onClick={() => changemap(item.lat, item.lang)}>
            <div class="box-header">
              <div class="box-title">
                <h4 class="heading">{item.n}</h4>
              </div>
            </div>

            <div class="box-body">
              <ul class="options">
                <li></li>
              </ul>
              <address>{item.ad}</address>
            </div>

            <div class="box-footer">
              <ul class="">
                <a class="BookNow" href={sessionUrl} style={{ textAlign: "left" }}>Book Tickets</a>&nbsp;
                {/* <a class="BookNow" href="#" style={{ textAlign: "left"}}>Order Snacks</a> */}
              </ul>
            </div>

          </div>


        </>
      )
    });

    return (
      <>
        <Header />
        <>
          <section class="cinema_section">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="cinema_title">Cinema</div>
                </div>
                {/* mobile view of cinema /. */}
                {/* /. */}
                <div class="col-sm-4 col-xs-12 scrollstyle visible-xs" >
                  {cinemaRender}

                </div>
                {/* mobile view of cinema */}
                <div class="col-sm-8 col-xs-12">
                  <div class="_location_map_">
                    <iframe src={this.state.mapUrl} aria-hidden="false" tabindex="0"></iframe>
                  </div>
                </div>

                <div class="col-sm-4 col-xs-12 hidden-xs scrollstyle" >
                  {cinemaRender}


                </div>

              </div>
            </div>

          </section>
        </>
        <Footer />
      </>
    );
  }
}

export default Cinema;

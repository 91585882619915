import React, { Component } from "react";
import * as Constants from "../Constants";
import "../App.css";
import "../CSS/food.css";
import axios from "axios";
import swal from "sweetalert";
import Loading from "./Loading";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class Payment extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    cinemaname: this.props.match.params.cinemaname,
    moviename: this.props.match.params.moviename,
    tckDetailsOutput: {},
    tckDetails: [],
    selectSeat: [],
    totalPrice: 0,
    discount: 0,
    promocodeApplied: 0,
    payModes: {},
    selected_paymode: "",
    tnc_checked: false,
    promocode: 0,
  };

  componentDidMount() {
    let formDataTckDetails = new FormData(); //formdata object
    formDataTckDetails.append("cinemacode", localStorage.getItem("ccode")); //append the values with key, value pair
    formDataTckDetails.append("bookingid", localStorage.getItem("bookingid"));
    formDataTckDetails.append(
      localStorage.getItem("isQrFlow") ? "pktransid" : "transid",
      localStorage.getItem("transid")
    );
    formDataTckDetails.append("doreq", false);
    formDataTckDetails.append("userid", localStorage.getItem("userid"));
    formDataTckDetails.append("av", Constants.av);
    formDataTckDetails.append("pt", Constants.pt);
    formDataTckDetails.append("did", Constants.did);

    const tckDetailsRequest = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(Constants.api + "/api/trans/tckdetails/", formDataTckDetails)
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              this.setState({
                tckDetailsOutput: resp.data.output,
                selectSeat: resp.data.output.seat,
              });

              let tckDetailsArray = [];
              Object.keys(resp.data.output.f).forEach(function (key) {
                tckDetailsArray.push(resp.data.output.f[key]);
              });

              this.setState({
                tckDetails: tckDetailsArray,
                totalPrice: resp.data.output.a,
              });
            } else {
              console.log(resp.data.msg);
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const foodDetailsRequest = async () => {
      let foodFormDataTckDetails = new FormData(); //formdata object
      foodFormDataTckDetails.append(
        "pktransid",
        localStorage.getItem("bookingid")
      );
      foodFormDataTckDetails.append("userid", localStorage.getItem("userid"));
      foodFormDataTckDetails.append("av", Constants.av);
      foodFormDataTckDetails.append("pt", Constants.pt);
      foodFormDataTckDetails.append("did", Constants.did);
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        await axios
          .post(
            Constants.api + "/api/food/fooddetails/",
            foodFormDataTckDetails
          )
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              this.setState({
                tckDetailsOutput: resp.data.output,
                selectSeat: resp.data.output.seat,
              });

              let tckDetailsArray = [];
              Object.keys(resp.data.output.f).forEach(function (key) {
                tckDetailsArray.push(resp.data.output.f[key]);
              });

              this.setState({
                tckDetails: tckDetailsArray,
                totalPrice: resp.data.output.a,
              });
            } else {
              console.log(resp.data.msg);
              swal(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const payModes = async () => {
      try {
        let pay_mode_form = new FormData(); //formdata object
        pay_mode_form.append(
          "booktype",
          localStorage.getItem("isQrFlow") ? "FOOD" : "BOOKING"
        ); //append the values with key, value pair
        pay_mode_form.append("av", Constants.av);
        pay_mode_form.append("pt", Constants.pt);
        pay_mode_form.append("cinemacode", localStorage.getItem("ccode")); //append the values with key, value pair

        await axios
          .post(Constants.api + "/api/trans/getpaymode", pay_mode_form)
          .then((resp) => {
            //  casetype array
            if (resp.data.result === "success") {
              this.setState({ loading: 0 });
              console.log(resp.data.output);
              this.setState({ payModes: resp.data.output });
            } else {
              console.log(resp.data.msg);
              this.setState({ loading: 0 });
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    if (localStorage.getItem("isQrFlow")) {
      foodDetailsRequest();
    } else {
      tckDetailsRequest();
    }
    payModes();
  }
  componentWillUnmount() {}
  onBackButtonEvent = () => {
    alert("asdasdasd");
  };
  applyPromocode = () => {
    if (this.state.promocode != 0) {
      this.setState({ loading: 1 });
      try {
        let promoData = new FormData(); //formdata object
        promoData.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
        promoData.append("bookingid", localStorage.getItem("bookingid"));
        promoData.append("transid", localStorage.getItem("transid"));
        promoData.append("booktype", "BOOKING");
        promoData.append("pt", "WEBSITE");
        promoData.append("promocode", this.state.promocode);

        // const resp =
        axios
          .post(Constants.api + "/api/payment/promocode", promoData)
          .then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.data.result === "success") {
              this.setState({
                loading: 0,
                promocodeApplied: 1,
                discount: resp.data.output.di,
              });
              swal(
                "Congratulations",
                "You got a discount of INR " + resp.data.output.di + " !!",
                "success"
              );
            } else {
              this.setState({
                loading: 0,
              });
              swal(resp.data.msg);
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    } else swal("", "Please enter a valid promocode", "error");
  };
  promocodeRollback = () => {
    this.setState({ loading: 1 });
    try {
      let promoData = new FormData(); //formdata object
      promoData.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
      promoData.append("bookingid", localStorage.getItem("bookingid"));
      promoData.append("booktype", "BOOKING");
      promoData.append("pt", "WEBSITE");
      promoData.append("mobile", localStorage.getItem("userphone"));

      // const resp =
      axios
        .post(Constants.api + "/api/payment/pcrollback", promoData)
        .then((resp) => {
          //  casetype array
          console.log(resp);
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
              discount: 0,
              promocodeApplied: 0,
            });
            swal("", "Proocode has been removed successfully.", "info");
          } else {
            this.setState({
              loading: 0,
            });
            swal(resp.data.msg);
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  selectPaymodel(paymode = "ALL") {
    this.setState({ selected_paymode: paymode });
  }
  changeTnc = (event) => {
    console.log(event.target.checked);
    if (event.target.checked == true) {
      this.setState({ tnc_checked: true });
    } else {
      this.setState({ tnc_checked: false });
    }
  };
  initiatePayment() {
    console.log(this.state.moviename);
    var paytype = this.state.selected_paymode;
    if (paytype == "") {
      paytype = document.getElementsByClassName("active_paymode")[0].innerHTML;
    }
    console.log(paytype);
    this.setState({ loading: 1 });
    try {
      if (paytype == "UPI") paytype = "UPI";
      if (paytype == "Net Banking") paytype = "NB";
      if (paytype == "Credit Card") paytype = "CC";
      if (paytype == "Debit Card") paytype = "DB";
      if (paytype == "Paytm") paytype = "WB";
      let paymentData = new FormData(); //formdata object
      paymentData.append("userid", localStorage.getItem("userid")); //append the values with key, value pair
      paymentData.append("bookingid", localStorage.getItem("bookingid"));
      paymentData.append("transid", localStorage.getItem("transid"));
      paymentData.append("unpaid", false);
      paymentData.append(
        "booktype",
        localStorage.getItem("isQrFlow") ? "FOOD" : "BOOKING"
      );
      paymentData.append("pt", "WEBSITE");
      paymentData.append("ptype", paytype);
      console.log(this.state.moviename);
      console.log(this.state.mcode);
      // const resp =
      axios
        .post(Constants.api + "/api/payment/paytmex/hmac", paymentData)
        .then((resp) => {
          //  casetype array
          console.log(resp);
          if (resp.data.result === "success") {
            this.setState({
              loading: 0,
            });
            console.log(resp.data.output);
            let blink_obj = {
              bookid: resp.data.output.bookid,
              hmackey: resp.data.output.hmackey,
              amount: resp.data.output.amount,
            };
            this.makePayment(blink_obj);
          } else {
          }
          //movie array
        });
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  makePayment = (obj) => {
    var paytm_anj_config = {
      root: "",
      flow: "DEFAULT",
      data: {
        orderId: obj.bookid /* update order id */,
        token: obj.hmackey /* update token value */,
        tokenType: "TXN_TOKEN",
        amount: obj.amount /* update amount */,
      },
      handler: {
        notifyMerchant: function (eventName, data) {
          console.log("notifyMerchant handler function called");
          console.log("eventName => ", eventName);
          console.log("data => ", data);
          if (eventName == "APP_CLOSED") {
            window.location = "/cancel/trans";
          }
        },
      },
    };
    console.log(paytm_anj_config);
    if (window.Paytm && window.Paytm.CheckoutJS) {
      // initialze configuration using init method
      console.log("initialze configuration using init method");
      window.Paytm.CheckoutJS.init(paytm_anj_config)
        .then(function onSuccess() {
          console.log("Before JS Checkout invoke");
          // after successfully update configuration invoke checkoutjs
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log("Error => ", error);
        });
    }
  };

  checkTransactionStatus = () => {
    const interval = setInterval(() => {
      console.log("This will run every second!");
    }, 5000);
    clearInterval(interval);
  };

  promocodeChange = (event) => {
    this.setState({ promocode: event.target.value });
  };

  render() {
    if (this.state.loading == 1) {
      return (
        <>
          <Loading />
        </>
      );
    }

    const tckDetailsRender = this.state.tckDetails.map(function (tck, i) {
      return (
        <>
          <React.Fragment key={i}>
            {tck.it.map((det, j) => {
              return (
                <li>
                  <div class="_food_item_name">{det.n}</div>
                  <div class="_food_item_name">
                    {det.n.startsWith("GST Number") ? "" : "INR"} {det.v}
                  </div>
                </li>
              );
            })}
          </React.Fragment>
        </>
      );
    });
    const selectSeatRender = this.state.selectSeat.map(function (item, i) {
      return (
        <>
          <React.Fragment key={i}>
            <li>{item.n}</li>
          </React.Fragment>
        </>
      );
    });

    const cancelTransaction = async () => {
      this.setState({ loading: 1 });
      try {
        let paymentData = new FormData(); //formdata object
        paymentData.append("bookingid", localStorage.getItem("bookingid"));
        paymentData.append("transid", localStorage.getItem("transid"));
        paymentData.append("cinemacode", localStorage.getItem("ccode"));
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        // const resp =
        axios
          .post(Constants.api + "/api/trans/canceltrans", paymentData)
          .then((resp) => {
            //  casetype array
            console.log(resp);
            //window.location = "/";
            this.props.history.go(-2);
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const backButtonClicked = () => {
      cancelTransaction();
    };

    return (
      <>
        <section class="foods_section">
          <div class="container">
            <div class="row">
              <div class="col-sm-8 col-xs-12">
                <div class="_payment_navlisting_">
                  <div class="form-group patment-box">
                    <div class="box_inline_">
                      {this.state.promocodeApplied == 0 ? (
                        <h4>Apply Offers</h4>
                      ) : (
                        <h4>Promocode Applied</h4>
                      )}
                    </div>
                    {this.state.promocodeApplied == 0 ? (
                      <form onSubmit={this.applyPromocode}>
                        <br />
                        <div class="box_inline_ _form_pay">
                          <div class="form-group">
                            <input
                              type="text"
                              class="cus_input"
                              name="promocode"
                              value={this.state.value}
                              onChange={this.promocodeChange}
                              placeholder="Enter Promocode"
                            />
                          </div>
                        </div>
                        <div class="_offer_btn">
                          <button
                            class="btn btn_blue btn_text text-uppercase"
                            type="submit"
                          >
                            Apply
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div class="_offer_btn">
                        <span class="btn coupon btn_text text-uppercase ">
                          {" "}
                          {this.state.promocode}
                          <span class="expire" onClick={this.promocodeRollback}>
                            {" "}
                            <i class="fa fa-close"></i>
                          </span>
                        </span>
                      </div>
                    )}
                  </div>

                  <div class="form-group patment-box _payment_title">
                    <h4>Payment Options</h4>

                    <div class="box_inline_">
                      <div class="patment_method_name">
                        {Object.keys(this.state.payModes)
                          .filter((tck) => this.state.payModes[tck].length)
                          .map((tck, i) =>
                            this.state.payModes[tck].map((pm, j) => (
                              <a
                                className={
                                  this.state.selected_paymode == pm.name ||
                                  (this.state.selected_paymode == "" &&
                                    i == 0 &&
                                    j == 0)
                                    ? "active active_paymode"
                                    : ""
                                }
                                onClick={() => this.selectPaymodel(pm.name)}
                              >
                                {pm.name}
                              </a>
                            ))
                          )}
                      </div>
                    </div>
                    <div className="box_inline_ _pay20px_ _form_pay _bdr_left_">
                      <div className="form-group">
                        <div className="box_inline_ _check_box_style">
                          <input
                            type="checkbox"
                            id="tnc"
                            name="tnc"
                            value="tnc"
                            onChange={this.changeTnc}
                          />
                          <label for="vehicle1">
                            {" "}
                            I have Read and Accepted the MovieTime Terms and{" "}
                            <br></br>Conditions of transaction
                          </label>
                        </div>
                      </div>
                      <div class="form-group">
                        <button
                          className="btn btn_blue btn_text text-uppercase"
                          disabled={!this.state.tnc_checked}
                          onClick={() => this.initiatePayment()}
                        >
                          Make Payment
                        </button>
                      </div>
                    </div>

                    <div class="box_inline_ _pay20px_ _form_pay _bdr_left_"></div>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-xs-12">
                <div class="sticky-right _w_100">
                  <div class="booking_summary _35px">
                    <h3 class="_summary_txt">Booking SUmmary</h3>
                    <img
                      class="img-responsive"
                      src={this.state.tckDetailsOutput.imh}
                      alt=""
                    />
                    <div class="_summary_details _border__">
                      {localStorage.getItem("isQrFlow") ? (
                        ""
                      ) : (
                        <div class="_item_list">
                          <p class="_name__">Movie</p>
                          <h4 class="movie_name__">
                            {this.state.tckDetailsOutput.m}{" "}
                            {this.state.tckDetailsOutput.cen}{" "}
                            {this.state.tckDetailsOutput.lg}
                          </h4>
                        </div>
                      )}
                      {localStorage.getItem("isQrFlow") ? (
                        <div class="_item_list">
                          <h4
                            style={{
                              fontSize: "18px",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Service on Aisle
                          </h4>
                        </div>
                      ) : (
                        ""
                      )}
                      <div class="_item_list">
                        <p class="_name__">Location</p>
                        <h4 class="movie_name__">
                          {this.state.tckDetailsOutput.c}
                        </h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Date</p>
                        <h4 class="movie_name__">
                          {" "}
                          {this.state.tckDetailsOutput.md}
                        </h4>
                      </div>

                      <div class="_item_list">
                        <p class="_name__">Time</p>
                        <h4 class="movie_name__">
                          {this.state.tckDetailsOutput.t}
                        </h4>
                      </div>
                    </div>
                  </div>
                  {localStorage.getItem("isQrFlow") ? (
                    ""
                  ) : (
                    <div class="ticket_prints _35px">
                      <span class="_name__">Seat Info</span>
                      <span class="_info__ _float_right">
                        <img
                          src={require("../assets/information.png").default}
                        />
                      </span>
                      <div class="_print_tickets">
                        <ul class="seats_checkout_ticket">
                          {selectSeatRender}
                        </ul>
                      </div>
                    </div>
                  )}
                  {localStorage.getItem("isQrFlow") ? (
                    ""
                  ) : (
                    <div class="foods_details_with_coupon">
                      <ul class="food_list_selected_">
                        {tckDetailsRender}
                        {this.state.promocodeApplied == 1 ? (
                          <li>
                            <div class="_food_item_name">Discount</div>
                            <div class="_food_item_name expire">
                              {" "}
                              - INR {this.state.discount}
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  )}
                  <div class="prices_tags">
                    <div class="ticket_pricing">Total</div>
                    <div class="ticket_Total_amount">
                      <i class="fa fa-inr" aria-hidden="true"></i>
                      <span class="_total_amt">
                        {parseFloat(this.state.totalPrice) -
                          parseFloat(this.state.discount)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="bottom_sticky">
          <button onClick={backButtonClicked} class="btn btn_outline btn_text">
            <i class="fa fa-long-arrow-left" aria-hidden="true">
              &nbsp;&nbsp;
            </i>
            Back
          </button>
        </div>
      </>
    );
  }
}

export default Payment;

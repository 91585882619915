import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import { Modal, Button, Form } from "react-bootstrap";

class PrivacyPolicy extends Component {

    // constructor(props) {
    //     super(props);
    // }

    state = {
        city: this.props.match.params.city,
        amcode: this.props.match.params.amcode,
        mname: this.props.match.params.moviename,
        lat: 0.0,
        lng: 0.0,
        date: '',
        lang: 'ALL',
        format: 'ALL',
        price: 'ALL',
        time: 'ALL',
        cinetype: 'ALL',
        special: 'ALL',
        hc: 'ALL',
        output: {},
        lngs: [],
        cinemas: [],
        days: []
    }


    render() {


        return (
            <>
                <Header />
                <>
                <section class="_movies_listing">
            <div class="container">
              <div class="_head_title">
                <h3 class="_title_movies">Privacy Policy</h3>
                <br/>
                <div class="row " style={{padding:"0 15px 15px 15px"}}>
                <div class="col-sm-12 col-xs-12">
                  <ul>
                    <p>This site and the materials, information, and the software, if applicable, are provided  as is and as available and without warranties of any kind, either express or implied. The information and content is based on the laws applicable in India. While efforts are made to keep the information on this site accurate and timely, <b>MOVIE TIME Pvt Ltd</b> does not guarantee or endorse the content, accuracy or completeness of the information, text, graphics, hyperlinks and other items contained herein or on any other server. To the fullest extent permissible, <b>MOVIE TIME Pvt Ltd</b> disclaims any warranties, express and/or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, freedom from computer virus and warranties arising from course of dealing or course of performance. <b>MOVIE TIME Pvt Ltd</b> does not represent or warrant that the availability of or the functions contained in the site will be uninterrupted or error free, that defects will be corrected or that the site or the server that makes the site available are free of viruses or other harmful components. <b>MOVIE TIME Pvt Ltd</b> does not make any warranties or representations regarding the use of the materials in the site in terms of their completeness, correctness, accuracy, adequacy, usefulness, timeliness, reliability or otherwise.</p>
                    <p><b>MOVIE TIME Pvt Ltd</b> is not responsible for any special, indirect, incidental or consequential damages that may arise from the use of or the inability to use the site and/or the materials contained on the site irrespective of whether the materials contained on the site are provided by <b>MOVIE TIME Pvt Ltd</b>.
              </p>
                    <p>No reproduction of any part of the site may be sold or distributed for commercial gain nor shall it be modified or incorporated in any other work, publication or site, whether in hardcopy or electronic format, including postings to any other site. <b>MOVIE TIME Pvt Ltd</b> reserves all other rights.
                </p>
                    <p>This site may contain some hyperlinks which lead out of this site. Information contained in any site linked from this site has not been reviewed for accuracy or legal sufficiency. <b>MOVIE TIME Pvt Ltd</b> is not responsible for the content of any such external hyperlinks and references to any external links should not be construed as an endorsement of the links or their content.
                  </p>
                    <p>No information on this site shall constitute an invitation to invest in <b>MOVIE TIME Pvt Ltd</b> . Neither MOVIE TIME, nor their respective officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to or use of this site or any site linked to it, including, without limitation, any loss or profit, indirect, incidental or consequential loss.
                    </p>
                    <p>By viewing this site you are deemed to agree to jurisdiction of the courts at New Delhi , India, in respect of any action arising therefrom or related thereto.</p>
                  </ul>
                </div>
              </div>
              </div>
              </div>
              </section>
           </>
             <Footer/>
            </>
        );
    }
}

export default PrivacyPolicy;

import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/profile.css';
import { Modal, Button, Form } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loading from './Loading';

class Profile extends Component {

  // constructor(props) {
  //     super(props);
  // }

  state = {
    output: {},
    currentBookings: [],
    pastBookings: [],
    Loading:0,
    profileOutput:{}
    // genreList:[],
    // genreLiked:[],
    // genre:[]

  }

  componentDidMount() {
    let formDataBH = new FormData();    //formdata object
    formDataBH.append('userid', localStorage.getItem('userid'));   //append the values with key, value pair
    formDataBH.append('city', localStorage.getItem('selectedcity'));
    formDataBH.append('av', Constants.av);
    formDataBH.append('pt', Constants.pt);
    formDataBH.append('did', Constants.did);

    const getBookingHistory = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState(
          {
            loading: 1
          }
        );
        // const resp = 
        await axios.post(Constants.api + '/api/history/myticketnew', formDataBH).then(resp => {
          //  casetype array   
          if (resp.data.result === 'success') {
            this.setState(
              {
                loading: 0
              }
            );
            console.log(resp.data.output);
            this.setState({
              output: resp.data.output
            })

            let currentBookingsArray = []
            Object.keys(resp.data.output.c).forEach(function (key) {
              currentBookingsArray.push(resp.data.output.c[key])
            });
            let pastBookingsArray = []
            Object.keys(resp.data.output.p).forEach(function (key) {
              pastBookingsArray.push(resp.data.output.p[key])
            });

            this.setState({
              currentBookings: currentBookingsArray,
              pastBookings: pastBookingsArray
            })

          
           

          } else {
            //console.log(resp.data.msg);
            alert(resp.data.msg);
            this.setState(
              {
                loading: 0
              }
            )
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getBookingHistory();


    let formDataPref = new FormData();    //formdata object
    formDataPref.append('userid', localStorage.getItem('userid'));   //append the values with key, value pair
    formDataPref.append('city', localStorage.getItem('selectedcity'));
    formDataPref.append('av', Constants.av);
    formDataPref.append('pt', Constants.pt);
    formDataPref.append('did', Constants.did);

    const getPreferences = async () => {
      try {
        console.log(this.state.moviename);
        console.log(this.state.mcode);
        this.setState(
          {
            loading: 1
          }
        );
        // const resp = 
        await axios.post(Constants.api + '/api/user/getprefrenences', formDataPref).then(resp => {
          //  casetype array   
          if (resp.data.result === 'success') {
            this.setState(
              {
                loading: 0
              }
            );
            
            this.setState({
              profileOutput: resp.data.output
            })

            // console.log(this.state.profileOutput);
            // let genreListArray = []
            // Object.keys(resp.data.output.genre.other).forEach(function (key) {
            //   genreListArray.push(resp.data.output.genre.other[key])
            // });
            // let genreLikedArray = []
            // Object.keys(resp.data.output.genre.liked).forEach(function (key) {
            //   genreLikedArray.push(resp.data.output.genre.liked[key])
            // });

            // this.setState({
            //   genreList: genreListArray,
            //   genreLiked: genreLikedArray
            // })

          } else {
            //console.log(resp.data.msg);
            alert(resp.data.msg);
            this.setState(
              {
                loading: 0
              }
            )
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    getPreferences();


  }

  render() {
    if (this.state.loading == 1) {
      return (
          <>
              <Loading />
          </>
      )
  }


    const currentBookingsRender = this.state.currentBookings.map(function (item, i) {
      if(item.typ=='BOOKING'){
        return (
          <div class="timeline-block">
           
            <div class="image timeline-element">
              <img class="image-holder" style={{ objectFit: "cover" }} src={item.im} />
            </div>
  
            <div class="desc timeline-element">
              <h3 class="title">{item.m} ({item.fmt}) <span class="place">at {item.c}</span></h3>
              <p class="extras seats"><span class="heading">Order ID: </span><span class="text">{item.bi}</span></p>
              <p class="extras seats"><span class="heading">Audi: </span><span class="text">{item.audi}</span></p>
              <p class="extras seats"><span class="heading">Seats Booked: </span><span class="text">{item.st} - {item.seats}</span></p>
              <p class="extras seats"><span class="heading">Date: </span><span class="text">{item.md}</span></p>
              <p class="extras seats"><span class="heading">Time: </span><span class="text">{item.t}</span></p>
              <p class="extras food">
  
              </p>
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="extras amount"><span class="heading text-uppercase">Amount <span class="hidden-sm-down">Paid</span> (INR) </span>
  
                    <span class="text big">{item.ft}</span>
                  </div>
                </div>
              </div>
  
  
            </div>
          </div>
        );
      }else{
        return (
          <div class="timeline-block">
             {/* <div class="date-time timeline-element hidden-xs">
  
              <div class="date bold">On 30 Jul 07:30 PM</div>
            </div> */}
  
 
  <div class="image timeline-element">
              <img class="image-holder" style={{ objectFit: "cover" }} src={require('../assets/food_image.jpg').default} />
            </div>
            <div class="desc timeline-element">
              <h3 class="title">{item.m} ({item.fmt}) <span class="place">at {item.c}</span></h3>
              <p class="extras seats"><span class="heading">Order ID: </span><span class="text">{item.bi}</span></p>
              <p class="extras seats"><span class="heading">Date: </span><span class="text">{item.md}</span></p>
              <p class="extras seats"><span class="heading">Time: </span><span class="text">{item.t}</span></p>
              {
                            item.f.map((food, j) => {
                                return (
                                  <p class="extras seats"><span class="heading"></span><span class="text">{food.n}</span></p>
                                    
                                )
                            })
                        }
              <p class="extras food">
  
              </p>
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="extras amount"><span class="heading text-uppercase">Amount <span class="hidden-sm-down">Paid</span> (INR) </span>
  
                    <span class="text big">{item.ft}</span>
                  </div>
                </div>
              </div>
  
  
            </div>
          </div>
        );
      }
     
    });

    const pastBookingsRender = this.state.pastBookings.map(function (item, i) {
      if(item.typ=='BOOKING'){
        return (
          <div class="timeline-block">
           
            <div class="image timeline-element">
              <img class="image-holder" style={{ objectFit: "cover" }} src={item.im} />
            </div>
  
            <div class="desc timeline-element">
              <h3 class="title">{item.m} ({item.fmt}) <span class="place">at {item.c}</span></h3>
              <p class="extras seats"><span class="heading">Order ID: </span><span class="text">{item.bi}</span></p>
              <p class="extras seats"><span class="heading">Audi: </span><span class="text">{item.audi}</span></p>
              <p class="extras seats"><span class="heading">Seats Booked: </span><span class="text">{item.st} - {item.seats}</span></p>
              <p class="extras seats"><span class="heading">Date: </span><span class="text">{item.md}</span></p>
              <p class="extras seats"><span class="heading">Time: </span><span class="text">{item.t}</span></p>
              <p class="extras food">
  
              </p>
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="extras amount"><span class="heading text-uppercase">Amount <span class="hidden-sm-down">Paid</span> (INR) </span>
  
                    <span class="text big">{item.ft}</span>
                  </div>
                </div>
              </div>
  
  
            </div>
          </div>
        );
      }else{
        return (
          <div class="timeline-block">
             {/* <div class="date-time timeline-element hidden-xs">
  
              <div class="date bold">On 30 Jul 07:30 PM</div>
            </div> */}
  
  <div class="image timeline-element">
              <img class="image-holder" style={{ objectFit: "cover" }} src={require('../assets/food_image.jpg').default} />
            </div>
            <div class="desc timeline-element">
              <h3 class="title">{item.m} ({item.fmt}) <span class="place">at {item.c}</span></h3>
              <p class="extras seats"><span class="heading">Order ID: </span><span class="text">{item.bi}</span></p>
              <p class="extras seats"><span class="heading">Date: </span><span class="text">{item.md}</span></p>
              <p class="extras seats"><span class="heading">Time: </span><span class="text">{item.t}</span></p>
              {
                            item.f.map((food, j) => {
                                return (
                                  <p class="extras seats"><span class="heading"></span><span class="text">{food.n}</span></p>
                                    
                                )
                            })
                        }
              <p class="extras food">
  
              </p>
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="extras amount"><span class="heading text-uppercase">Amount <span class="hidden-sm-down">Paid</span> (INR) </span>
  
                    <span class="text big">{item.ft}</span>
                  </div>
                </div>
              </div>
  
  
            </div>
          </div>
        );
      }
     
    });



    return (
      <>
        <Header />
        <>
          <section class="profile_section">
            <div class="container">

              <div class="row">
                <div class="col-sm-12 col-xs-12">
                <div class="synosis-cont">
                <p class="extras seats"><span class="heading">Name: </span><span class="text">{this.state.profileOutput.un} </span></p>
              <p class="extras seats"><span class="heading">email: </span><span class="text">{this.state.profileOutput.em}</span></p>
              <p class="extras seats"><span class="heading">Mobile: </span><span class="text">{this.state.profileOutput.ph}</span></p>
             
                
                   
                   
                                </div>

                  {/* <Tabs>
                    <TabList>
                      <div class="seat__title">
                        <div class="_box_title">
                          <ul class="nav nav-tabs _profile_tabs">
                            <Tab><li class="active"><a data-toggle="tab" href="#PREFERENCES">PREFERENCES</a></li></Tab>
                            <Tab><li><a data-toggle="tab" href="#BOOKING_HISTORY">BOOKING HISTORY</a></li></Tab>
                          </ul></div>
                      </div>
                    </TabList> */}

                    <div class="tab-content _50px">
                      {/* <TabPanel>

                        <div id="PREFERENCES" class="tab-pane">

                          <div class="_grid_profile_">

                            <div class="grid_profile">
                              <div class="control_label">What are your favourite genres?</div>
                            </div>

                            <div class="grid_profile">
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input class="form-check-input" name="movieGenre" type="checkbox" checked="true" />
                                  <span class="radio-tag tag tag-rounded">{item.na}</span>
                                </label>
                              </div>
                              
                            </div>

                          </div>
                          <div class="_grid_profile_">

                            <div class="grid_profile">
                              <div class="control_label">Which theatre you prefer the most ?</div>
                            </div>

                            <div class="grid_profile">
                              <form class="form-check-group black fx wrap fx--ai-fe">
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input class="form-check-input" name="movieGenre" type="checkbox" checked="true" />
                                    <span class="radio-tag tag tag-rounded tag-white">PVR Vegas 4DX Dwarka, PVR Vegas Dwarka, PVR Vegas Play House Dwarka, PVR Vegas LUXE Dwarka, PVR Vegas IMAX Dwarka</span>
                                  </label>
                                </div>


                              </form>

                            </div>

                          </div>

                        </div>
                      </TabPanel>
                     */}
                      {/* <TabPanel> */}
                        {/* <div id="BOOKING_HISTORY" class="tab-pane"> */}
                        <div id="BOOKING_HISTORY">
                          <div class="movie-timeline">

                            {currentBookingsRender}
                            {pastBookingsRender}


                          </div>


                        </div>
                      {/* </TabPanel> */}

                    </div>
                  {/* </Tabs> */}

                </div>

              </div>

            </div>
          </section>
        </>
        <Footer />
      </>
    );
  }
}

export default Profile;

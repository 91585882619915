import React, { Component } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import { Redirect } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

class NowShowing extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        nowShowing: [],
        genres: [],
        loading: 1
    }
    componentDidMount() {
        let formDataNS = new FormData();    //formdata object
        formDataNS.append('city', localStorage.getItem('selectedcity'));   //append the values with key, value pair
        formDataNS.append('av', Constants.av);
        formDataNS.append('pt', Constants.pt);
        formDataNS.append('did', Constants.did);
        formDataNS.append('userid', localStorage.getItem('userid'));
        formDataNS.append('dtmsource', 'local');
        formDataNS.append('mobile', '');

        const nowShowingRequest = async () => {
            try {
                const resp = await axios.post(Constants.api + '/api/content/nowshowing', formDataNS).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        let nowShowingArray = []
                        Object.keys(resp.data.output).forEach(function (key) {
                            nowShowingArray.push(resp.data.output[key])
                        });

                        this.setState({
                            nowShowing: nowShowingArray,
                        })
                        console.log('hello');
                        console.log(this.state.nowShowing);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
                this.setState(
                    {
                        loading: 1
                    }
                );
            }
        };
        nowShowingRequest();

    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }

        const trailerShow = (title, url) => {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            let videoUrl = "https://www.youtube.com/embed/" + video_id;
            this.setState({ setShow: 1, movietitle: title, videoUrl: videoUrl });
        }
        const closetrailer = () => {
            this.setState({ setShow: 0 });
        }

        const nowShowingRender = this.state.nowShowing.map(function (item, i) {
            // alert(item.mname);
            let nameForUrl = item.n.toString().toLowerCase().replaceAll(' ', '-');

            let bookUrl = '/moviesessions/' + localStorage.getItem('selectedcity') + '/' + nameForUrl + '/' + item.id + '?language=' + item.lng;
            return (
                <>
                    <React.Fragment key={i}>
                       
                        <div class="col-sm-3 col-xs-12">
                            <div class="movies_box_showing _radius_5px">
                                <div class="movie_poster">
                                    <div class="moives_img_showing _radius_5px _set_like">
                                        {(item.rt != '') ?
                                            <div class="new_release">New Release</div> : ''}
                                        <img class="img-responsive" src={item.miv} alt="" />
                                        {/* <i class="fa fa-heart-o abs_icon _clr_white" aria-hidden="true"></i> */}
                                        <div class="movies_name_and_format_showing">
                                            <h4>{item.n}</h4>
                                            <div class="_eng_showing">
                                                <span>{item.ce}</span>
                                                <span>-</span>
                                                <span>{item.lng}</span>
                                            </div>
                                            <div class="book_now_showing">
                                                <a href={bookUrl}><button class="BookNow_showing">Book Now</button></a>
                                                {(item.videoUrl) ? <i class="fa fa-play _floa_right play_showing" onClick={() => trailerShow(item.name, item.videoUrl)} aria-hidden="true"></i> : ''}
                                            </div>
                                        </div>
                                    </div>      </div>  </div></div>
                    </React.Fragment>
                </>
            )
        });

        return (
            <>
                <Header />
                {/* Now showing */}
                <section class="_movies_listing">
                    <div class="container">
                        <div class="_head_title">
                            <h3 class="_title_movies">Now SHowing</h3>
                            <div class="col-sm-7 col-xs-12">
                                {/* <div class="row">
                                    <div class="tab_list_movies">
                                        <ul class="nav nav-tabs">
                                            <li class="active"><a data-toggle="tab" href="#all">All</a></li>
                                            <li><a data-toggle="tab" href="#adventure">Adventure</a></li>
                                            <li><a data-toggle="tab" href="#action">Action</a></li>
                                            <li><a data-toggle="tab" href="#comedy">Comedy</a></li>
                                            <li><a data-toggle="tab" href="#thriller">Thriller</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div class="col-sm-5 col-xs-12">
                                <form class="_custome_form _15px_ _floa_right">
                                    <div class="format_movies">
                                        <div class="location_detect">
                                            <div class="form-group select-cutom _icon_set _bg_gray">
                                                <select class="form-control" name="department" required="">
                                                    <option value="">Format</option>
                                                </select>
                                                <img src="assets/format_icon.svg" class="_set_abs" />                  </div>
                                        </div>
                                    </div>
                                    <div class="format_movies">
                                        <div class="location_detect">
                                            <div class="form-group select-cutom _icon_set _bg_gray">
                                                <select class="form-control" name="department" required="">
                                                    <option value="">Language</option>
                                                </select>
                                                <img src="assets/global.svg" class="_set_abs" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="tab-content">
                                <div id="all" class="tab-pane fade in active">
                                    {(this.state.nowShowing.length > 0) ? nowShowingRender : <div class="col-sm-7 col-xs-12">No Movies Available </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <Footer />
                <Modal show={this.state.setShow} animation={false}>
                    <Modal.Header closeButton onClick={closetrailer}>
                        <Modal.Title>{this.state.movietitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <><iframe width="100%" height="400"
                            src={this.state.videoUrl}>
                        </iframe></>
                    </Modal.Body>
                    <Modal.Footer>
                        <img src={require('../assets/logo.png').default} alt="" width="200" />
                    </Modal.Footer>

                </Modal>

            </>
        );
    }
}

export default NowShowing;
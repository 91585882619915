import React, { Component } from 'react';
import '../App.css';
// import '../CSS/aboutus.css';
import Footer from './Footer';
import Header from './Header';

class Aboutus extends Component {
    render() {
        return (
            <>
                <Header/>
               
                <section class="about_section">
    <div class="container">
      <div class="title_about">Movietime cinemas </div>
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <div class="text_about">
            <p>Movietime Cinemas is one of India’s oldest multiplex chains and has only shown tremendous growth and continuous progress over the years. Lalit Kapur, is the Managing Director of Movietime Cinemas and the backbone of the aspiring expansion.</p>
          </div>
          <div class="img_blow">
          <img src={require('../assets/about_img_2.jpg').default} alt="" />
          </div>
        </div>
        <div class="col-sm-4 col-xs-12">
            <div class="text_about">
                <p>
                  Since 1998, along with his younger brother Anil Kapur, he has driven the organization to a remarkable growth. It operates multiplexes in all the major cities of India.
                </p>
                <p>It all began for the company in 1968 when O. P. Kapur founded Kamal Cinema in the Green Park area of Delhi. Year after year, the cinema saw an increase in the number of people coming to enjoy their favourite films and the cinema soon became an experience individual would cherish.
                </p>
                <p>In India, Movietime Cinemas entered the business with Movietime: Malad West, Mumbai as their first location and now operates a total of 65 screens in 22 properties across 15 cities all over India. Recognizing tremendous growth in the foreign markets, Mr. O.P Kapur’s eldest son, Arun Kapur moved to Peru, South America and presently has established more than 150 screens under brand names “Cinestar” & “Movietime”.
                  </p>
              </div>  
        </div>
        <div class="col-sm-4 col-xs-12">
          <div class="img_blow">
              <img src={require('../assets/about_img_1.jpg').default} alt=""/>
          </div> 
        </div>
      </div>
    </div>
    </section>
  
    <section class="team_member_about" style={{display:"none"}}>
      <div class="container">
        <div class="row">
          <div class="col-sm-4 col-xs-12">
            <div class="title_about">Movietime cinemas </div>
            <div class="img_blow">
                <img src={require('../assets/team/img_1.jpg').default} alt=""/>
              </div>
          </div>
          <div class="col-sm-8 col-xs-12" >
                  <ul class="nav nav-tabs _custom_tabs" style={{display:"none"}}>
                      <li class="active">
                        <a data-toggle="tab" href="#team1">
                        <img src={require('../assets/team/img_2.png').default} alt=""/></a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#team2">
                        <img src={require('../assets/team/img_3.png').default} alt=""/>
                      </a>
                    </li>
                      <li>
                        <a data-toggle="tab" href="#team3">
                            <img src={require('../assets/team/img_4.png').default} alt=""/>
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#team4">
                            <img src={require('../assets/team/img_5.png').default} alt=""/>
                        </a>
                      </li>
                      <li>
                          <a data-toggle="tab" href="#team5">
                              <img src={require('../assets/team/img_6.png').default} alt=""/>
                          </a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#team6">
                                <img src={require('../assets/team/img_7.png').default} alt=""/>
                            </a>
                          </li>
                  </ul>
                <div class="tab-content  p_20px_0 _border_top">
                    <div id="team1" class="tab-pane fade in active">
                      <div class="team_title_">Lalit Kapur</div>
                      <div class="designation_team _30_px___">Co-Founder</div>
                      <div class="text_about">
                        <p>Movietime Cinemas endeavors to set up more than 100 screens till 2020.[1] The new upcoming screens will be equipped with advanced projection & audio technology for finest movie experience for the patrons.</p>
                      </div>
                   </div>
                 
                   <div id="team2" class="tab-pane fade">
                      <div class="team_title_">Lalit Kapur</div>
                      <div class="designation_team _30_px___">Co-FOunder</div>
                      <div class="text_about">
                        <p>Movietime Cinemas endeavors to set up more than 100 screens till 2020.[1] The new upcoming screens will be equipped with advanced projection & audio technology for finest movie experience for the patrons.</p>
                      </div>
                   </div>
                 
                   </div>
                   
          </div>
        </div>
      </div>
    </section>
   <Footer/>
                
            </>);
    }
}

export default Aboutus;
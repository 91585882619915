import React, { Component } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import { Redirect } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

class Career extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        career: [],
        genres: [],
        whatsappurl: "https://api.whatsapp.com/send?phone=919650130041&text=Hello I want to apply for a job position.",
        emailurl: "mailto:hrmanager@movietimecinemas.co.in?subject=Applying for the job position",
        loading: 1
    }
    componentDidMount() {
        let formDataNS = new FormData();    //formdata object
        formDataNS.append('av', Constants.av);
        formDataNS.append('pt', Constants.pt);
        formDataNS.append('did', Constants.did);


        const careerRequest = async () => {
            try {
                const resp = await axios.post(Constants.api + '/api/career/detail', formDataNS).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        let careerArray = []

                        Object.keys(resp.data.output).forEach(function (key) {
                            careerArray.push(resp.data.output[key])
                        });


                        this.setState({
                            career: careerArray,
                        })
                        console.log('hello');
                        console.log(this.state.career);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
                this.setState(
                    {
                        loading: 1
                    }
                );
            }
        };
        careerRequest();

    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }



        const careerRender = this.state.career.map(function (item, i) {
            // alert(item.mname);
            let url = "/careerdetail/" + item.title + "/" + item.id;


            return (
                <>
                <div class="col-sm-3 staff-box">
                            {/* <img src="resources/img/Group 53573.png"/> */}
                            <img src={require('../assets/career.png').default} alt="" />

                            <h3>{item.title}</h3>
                            <a href={url} class="BookNow">More Info <ion-icon name="arrow-forward-outline" class="arrow-icons"></ion-icon></a>
                        </div>
                </>
            )
        });

        return (
            <>
                <Header />
                <header class="jumbotron">
                    <div class="row hero-text">
                        {/* <h1>Careers</h1>
                        <p>We are rapidly expanding Multiplex Brand that has Young Brigade, who dream big, work hard for the common goal.
                        To fuel our growth, we welcome the talent that believes in self, open to learn and carries strong implementation skills.
                        If you are passionate about making a difference and dare to turn big dreams into reality, it is time for you to connect to us.
                We have career opportunities for bright candidates in Multiplexes.</p> */}
                    </div>
                </header>
                {/* Now showing */}
                <section class="section-staff">

                    {careerRender}

                </section>
                <section class="contact">
                    <div class="row contact-content">
                        <h3>Contact Us</h3>
                        <p>Share your resume now</p>
                        <button class="btn btn-default mail"><ion-icon name="mail-outline" class="small-icons"></ion-icon><a href={this.state.emailurl}>hrmanager@movietimecinemas.co.in</a></button>
                        <button class="btn btn-default phone"><ion-icon name="logo-whatsapp" class="small-icons"></ion-icon> <a href={this.state.whatsappurl}>+91 9650130041</a></button>

                    </div>
                </section>
                <Footer />


            </>
        );
    }
}

export default Career;
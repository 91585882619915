import React, { Component } from 'react';
import '../App.css';
import Footer from './Footer';
import Header from './Header';
import { Container, Col, Row } from 'react-bootstrap';

class BookingPage extends Component {
    render() {
        return (
            <>
          <Header/>
          <section className="_booking_page_">
          <div className="Title_Booking_Page">
          <Container>
          <Row>
            <Col sm={12} col-xs={12}>
              <h5>Select Booking</h5>
            </Col>
          </Row>
          </Container>
          </div>
          <Container>
          <Col sm={12} col-xs={12}>
          <Row>
            <div className="_cont_booking">
            <h4>MovieTime Shalimar Bagh Delhi</h4>
            <p>Please select one option from below:</p>
            </div>
          </Row>
          </Col>
          <Row>
          
            <Col sm={6} col-xs={12}>
              <div className="img_booking_page img-responsive">
                <img src={require ("../assets/booking-page/booking_page_img_2.png").default} />
              </div>
              <div className="grid_booking_page">
                <div className="_cont_book">
                  <h4>Book your Movie Ticket</h4>
                </div>
                <div className="text_booking">
                <button class="btn btn_blue _float_right btn_text">Next &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </Col>
            <Col sm={6} col-xs={12}>
            <div className="img_booking_page img-responsive">
                <img src={require ("../assets/booking-page/booking_page_img_1.png").default}/>
              </div>
              <div className="grid_booking_page">
                <div className="_cont_book">
                  <h4>Order your Food & Beverage </h4>
                </div>
                <div className="text_booking">
                <button class="btn btn_blue _float_right btn_text">Next &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </Col>
            </Row> 
            </Container> 
            </section>

         <Footer/>
                
            </>);
    }
}

export default BookingPage;
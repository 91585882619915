import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import { Modal, Button, Form } from "react-bootstrap";

class PageNotFound extends Component {

  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: '',
    lang: 'ALL',
    format: 'ALL',
    price: 'ALL',
    time: 'ALL',
    cinetype: 'ALL',
    special: 'ALL',
    hc: 'ALL',
    output: {},
    lngs: [],
    cinemas: [],
    days: []
  }


  render() {


    return (
      <>
        <div class="movie-bg-colour">
          <div class="container">
            <div class="movie-time-img">
              <div class="movie-time-logo">
                <div class="movie-logo">
                  <img src={require('../assets/logo.a2ae4fb0.png').default} />
                </div>
                <div class="download-aap">
                  <div class="download-info">
                    <p>DOWNLOAD OUR MOBILE APP</p>
                    <div class="app-img">
                      <div class="google-play">
                        <a href="http://onelink.to/e9ptem" target="blank">
                          <img src={require('../assets/googleplay.94cd088f.png').default} />
                        </a>
                      </div>
                      <div class="app-store">
                        <a href="http://onelink.to/e9ptem" target="blank">
                          <img src={require('../assets/appstore.9e0fbe64.png').default} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="movie-right-img">
                <img src={require('../assets/movie-img (2).png').default} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PageNotFound;

import React, { Component } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import '../App.css';


class Footer extends Component {
  constructor(props) {
    super(props);

  }
  state = {
    showfaq: 0,
    showtnc: 0,
    showpp: 0,
    cinemaUrl: "/cinema/" + localStorage.getItem('selectedcity'),
    upcomingcinemaUrl: "/upcomingcinema/" + localStorage.getItem('selectedcity')
  }
  render() {
    const openfaqmodal = () => {
      this.setState({ showfaq: 1 });
    }
    const closefaq = () => {
      this.setState({ showfaq: 0 });
    }
    const opentncmodal = () => {
      this.setState({ showtnc: 1 });
    }
    const closetnc = () => {
      this.setState({ showtnc: 0 });
    }
    const openppmodal = () => {
      this.setState({ showpp: 1 });
    }
    const closepp = () => {
      this.setState({ showpp: 0 });
    }
    return (
      <>
        <section class="_footer_">
          <footer>
            <div class="container">
              <div class="row">
                <div class="col-sm-2 col-xs-12">
                  <ul class="footer_link">

                    <li><a href="/aboutus">About us</a></li>
                    <li><a href="/faq">FAQ’s</a></li>
                    <li><a href="/offers">Offers</a></li>
                    {/* <li><a href="">Investors Section</a></li>
                <li><a href="">Career</a></li> */}
                  </ul>
                </div>
                <div class="col-sm-2 col-xs-12">
                  <ul class="footer_link">
                    <li><a href="/contactus">Contact Us</a></li>
                    <li><a href="/advertise">Advertise</a></li>
                    <li><a href="/tnc">Terms & Conditions</a></li>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    {/* <li><a href="">News</a></li> */}
                  </ul>
                </div>
                <div class="col-sm-2 col-xs-12">
                  <ul class="footer_link">
                    {/* <h4 class="_botom_20px">Movietime</h4> */}

                    <li><a href="/nowshowing">Now Showing Movies</a></li>
                    <li><a href="/upcoming">Upcoming Movies</a></li>
                    <li><a href="/bulkbooking">Bulk Booking</a></li>
                    <li><a href="/privatescreening">Private Screening</a></li>
                    {/* <li><a href="">Beyond Movies</a></li> */}
                  </ul>
                </div>
                <div class="col-sm-2 col-xs-12">
                  <ul class="footer_link">
                    {/* <h4 class="_botom_20px">Movietime</h4> */}
                    <li><a href={this.state.cinemaUrl}>Movietime Cinemas</a></li>
                    <li><a href={this.state.upcomingcinemaUrl}>Upcoming Cinemas</a></li>

                  </ul>
                </div>

                <div class="col-sm-3 col-xs-12">
                  <ul class="footer_link">
                    {/* <h4 class="_botom_20px">Privacy</h4> */}

                    {/* <li><a href="">Terms of use</a></li> */}
                    <li style={{ color: "#fff" }}>Movie Time Cinemas Pvt Ltd </li>
                    <li style={{ color: "#fff" }}> 1st Floor
                      ,Kamal Cinemas Building</li>
                    <li style={{ color: "#fff" }}> Safdarjung Enclave, New Delhi- 110029</li>
                    <li style={{ color: "#fff" }}><a href="mailto:info@movietimecinemas.in"> <i class="fa fa-envelope"></i> info@movietimecinemas.in </a></li>

                    <li style={{ color: "#fff" }}><a href="tel:9068853335"> <i class="fa fa-phone"></i> 9068853335</a></li>
                  </ul>
                </div>

              </div>
            </div>
          </footer>
        </section>


        <section class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-sm-9 col-xs-12">
                <div class="cont_footer">
                  <p>Copyright Wemonde 2020 | All Rights Reserved</p>
                </div>
              </div>
              <div class="col-sm-3 col-xs-12">
                <div class="cont_footer text-right">
                  <a href="https://www.facebook.com/movietimecinemas" target="_blank" class="fa fa-facebook" aria-hidden="true"></a>
                  <a href="https://instagram.com/movietimecinemas?igshid=872hj6zmvctl" target="_blank" class="fa fa-instagram" aria-hidden="true"></a>
                </div>
              </div>
            </div>
          </div>
        </section>



      </>);
  }
}

export default Footer;
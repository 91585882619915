import React, { Component } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import { Redirect } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

class Upcoming extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        upcoming: [],
        genres: [],
        loading: 1
    }
    componentDidMount() {
       
        let formDataUP = new FormData();    //formdata object
        formDataUP.append('city', localStorage.getItem('selectedcity'));   //append the values with key, value pair
        formDataUP.append('av', Constants.av);
        formDataUP.append('pt', Constants.pt);
        formDataUP.append('did', Constants.did);
        formDataUP.append('userid', localStorage.getItem('userid'));
        formDataUP.append('lang', 'ALL');

        const upcomingRequest = async () => {
            try {
                const resp = await axios.post(Constants.api + '/api/content/comingsoon', formDataUP).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        let upcomingArray = []
                        Object.keys(resp.data.output).forEach(function (key) {
                            upcomingArray.push(resp.data.output[key])
                        });

                        this.setState({
                            upcoming: upcomingArray,
                        })
                        console.log('hello');
                        console.log(this.state.upcoming);
                    } else {
                        console.log(resp.data.msg);
                        this.setState({loading:0})
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        upcomingRequest();

    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                   <Loading/>
                </>
            )
        }
        const trailerShow = (title, url) => {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            let videoUrl = "https://www.youtube.com/embed/" + video_id;
            this.setState({ setShow: 1, movietitle: title, videoUrl: videoUrl });
        }
        const closetrailer = () => {
            this.setState({ setShow: 0 });
        }
      
        const upcomingRender = this.state.upcoming.map(function (item, i) {
            // alert(item.mname);
            // let nameForUrl = item.name.toString().toLowerCase().replaceAll(' ', '-');

            // let bookUrl = '/moviesessions/Delhi-NCR/' + nameForUrl + '/' + item.masterMovieId + '?language=' + item.lng;
            return (
                <>
                    <React.Fragment key={item.masterMovieId}>
                    <div class="col-sm-3 col-xs-12">
                            <div class="movies_box_showing _radius_5px">
                                <div class="movie_poster">
                                    <div class="moives_img_showing _radius_5px _set_like">
                                        
                                            {/* <div class="new_release">{item.date_caption}</div>  */}
                                        <img class="img-responsive" src={item.miv} alt="" />
                                        {/* <i class="fa fa-heart-o abs_icon _clr_white" aria-hidden="true"></i> */}
                                        <div class="movies_name_and_format_showing">
                                            <h4>{item.name}</h4>
                                            <div class="_eng_showing">
                                                <span>{item.censor}</span>
                                                <span>-</span>
                                                <span>{item.language}</span>
                                            </div>
                                         
                <div class="book_now_showing">
                {(item.videoUrl) ?    <button class="BookNow_showing" onClick={() => trailerShow(item.name, item.videoUrl)}> Play Trailer</button>
                :''}
                </div>
                                        </div>
                                    </div>      </div>  </div></div>  </React.Fragment>
                </>
            )
        });


        return (
            <>
                <Header />
                {/* Upcoming */}
                <section class="_movies_listing">
                    <div class="container">
                        <div class="_head_title">
                            <h3 class="_title_movies">Upcoming Movies</h3>
                            {/* <div class="col-sm-5 col-xs-12">
                                <form class="_custome_form _15px_ _floa_right">
                                    <div class="format_movies">
                                        <div class="location_detect">
                                            <div class="form-group select-cutom _icon_set _bg_gray">
                                                <select class="form-control" name="department" required="">
                                                    <option value="">Format</option>
                                                </select>
                                                <img src="assets/format_icon.svg" class="_set_abs" />                  </div>
                                        </div>
                                    </div>
                                    <div class="format_movies">
                                        <div class="location_detect">
                                            <div class="form-group select-cutom _icon_set _bg_gray">
                                                <select class="form-control" name="department" required="">
                                                    <option value="">Language</option>
                                                </select>
                                                <img src="assets/global.svg" class="_set_abs" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="tab-content">
                                <div id="all" class="tab-pane fade in active">
                                    {upcomingRender}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Modal show={this.state.setShow} animation={false}>
                    <Modal.Header closeButton onClick={closetrailer}>
                        <Modal.Title>{this.state.movietitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <><iframe width="100%" height="400"
                            src={this.state.videoUrl}>
                        </iframe></>
                    </Modal.Body>
                    <Modal.Footer>
                    <img src={require('../assets/logo.png').default} alt="" width="200" />
                    </Modal.Footer>

                </Modal>
            </>
        );
    }
}

export default Upcoming;
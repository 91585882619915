import React, { Component } from 'react';
import TransformText from "./TransformText";

class Apple extends Component {



  render() {


    return (
      <>
      <TransformText text='{
    "applinks": {
        "apps": [],
        "details": [{
                "appID": "ZL63VXAC8C.com.wemonde.movietime",
                "paths": ["*"]
            }
        ]
    }
}' /> 
        
      </>
    );
  }
}

export default Apple;

import React, { Component } from 'react';
import * as Constants from '../Constants';
import SimpleReactValidator from 'simple-react-validator';
// import OtpInput from "react-otp-input";
import '../App.css';
import axios from "axios";
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import app_logo from '../assets/logo.png'


const timeoutLength = 4000;


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            lat: '',
            lng: '',
            search: '',
            searchFilter: 'NA',
            showotplogin: 0,
            mobile: '',
            password: '',
            otp: '',
            showMenu: 0,
            otpshow: 0,
            showlogin: 0,
            resetpassshow: 0,
            showregister: 0,
            showseachdropdown: 1,
            searchArray: [],
            banner: [],
            cinemaUrl: "/cinema/" + localStorage.getItem('selectedcity')
        }
        this.cityChange = this.cityChange.bind(this);
        this.otpvalidator = new SimpleReactValidator();
        this.forgotvalidator = new SimpleReactValidator();
        this.resetvalidator = new SimpleReactValidator();
        this.resetpassvalidator = new SimpleReactValidator();
        this.otploginvalidator = new SimpleReactValidator();
        this.registervalidator = new SimpleReactValidator();
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        if (localStorage.getItem('userid') == null) {
            localStorage.setItem('userid', 0);


        }

        let formData = new FormData();    //formdata object
        formData.append('country', Constants.country);   //append the values with key, value pair
        formData.append('av', Constants.av);
        formData.append('pt', Constants.pt);
        formData.append('userid', localStorage.getItem('userid'));
        formData.append('lat', this.state.lat);
        formData.append('lng', this.state.lng);

        const sendGetRequest = async () => {
            await this.getMyLocation();
            try {
                const resp = await axios.post(Constants.api + '/api/content/cities', formData).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        if (!localStorage.getItem('selectedcity')) {
                            localStorage.setItem('selectedcity', resp.data.output.cc.name);
                        }

                        let citiesArray = []
                        Object.keys(resp.data.output.ot).forEach(function (key) {
                            citiesArray.push(resp.data.output.ot[key])
                        });

                        this.setState({
                            cities: citiesArray,
                        })
                        console.log(this.state.cities);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 1
                            }
                        );
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        // if (!sessionStorage.getItem('selectedcity'))
        sendGetRequest();

    }
    getsearchmovies = () => {
        if (this.state.search == '') {
            this.setState({ showseachdropdown: 0 })
        }
        else
            this.setState({ showseachdropdown: 1 })
        let searchdata = new FormData();    //searchdata object
        searchdata.append('pt', Constants.pt);
        searchdata.append('av', Constants.av);
        searchdata.append('city', localStorage.getItem('selectedcity'));
        searchdata.append('text', this.state.search);
        searchdata.append('searchFilter', this.state.searchFilter);

        axios.post(Constants.api + '/api/content/search', searchdata)
            .then(res => {
                // console.log(res);
                if (res.data.result == 'success') {
                    console.log(res.data.output);
                    let searchArray = []
                    Object.keys(res.data.output.m).forEach(function (key) {
                        searchArray.push(res.data.output.m[key])
                    });
                    Object.keys(res.data.output.t).forEach(function (key) {
                        searchArray.push(res.data.output.t[key])
                    });

                    this.setState({
                        searchArray: searchArray,
                    })
                }
                else {
                    // this.setState({alert:res.data.msg});
                    swal(res.data.msg, "info");
                }



            })
    }
    getMyLocation = (e) => {
        let location = null;
        let latitude = null;
        let longitude = null;
        if (window.navigator && window.navigator.geolocation) {
            location = window.navigator.geolocation
        }
        if (location) {
            location.getCurrentPosition(function (position) {

                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
                localStorage.setItem('lat', latitude);
                localStorage.setItem('lng', longitude);

            })
        }

        this.setState({ lat: latitude, lng: longitude });

    }
    logout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("useremail");
        localStorage.removeItem("userphone");
        localStorage.setItem("userid", '0');
        swal("", "You have logged out!", "info");
        window.location.reload("/");
    }
    handleForgotpassSubmit = event => {

        event.preventDefault();
        if (this.forgotvalidator.allValid()) {
            let logindata = new FormData();    //logindata object
            logindata.append('pt', Constants.pt);
            logindata.append('av', Constants.av);
            logindata.append('did', Constants.did);
            logindata.append('mobile', this.state.mobile);
            logindata.append('email', '');

            axios.post(Constants.api + '/api/user/forgotpassword', logindata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {
                        if (res.data.msg == 'ok') {
                            swal("Please Enter OTP Received on your Mobile Number.");
                            this.setState({ showforgotpass: 0, resetpassshow: 1 });
                        }
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal(res.data.msg, "info");
                    }



                })
        }
        else
            this.forgotvalidator.showMessages();
        this.forceUpdate();
    }
    handleOtpLoginSubmit = event => {

        event.preventDefault();
        if (this.otploginvalidator.allValid()) {
            let logindata = new FormData();    //logindata object
            logindata.append('pt', Constants.pt);
            logindata.append('mobile', this.state.mobile);

            axios.post(Constants.api + '/api/user/new/resendotp', logindata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {
                        swal("", "Please enter OTP received on your Mobile Number", "info");
                        if (res.data.output.otp_require == 'true') {
                            this.setState({ showotplogin: 0, otpshow: 1 });
                        }
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal(res.data.msg);
                    }



                })
        }
        else
            this.otploginvalidator.showMessages();
        this.forceUpdate();
    }
    handleLoginSubmit = event => {

        event.preventDefault();
        if (this.validator.allValid()) {
            let logindata = new FormData();    //logindata object
            logindata.append('av', Constants.av);
            logindata.append('pt', Constants.pt);
            logindata.append('did', Constants.did);
            logindata.append('mobile', this.state.mobile);
            logindata.append('password', this.state.password);

            axios.post(Constants.api + '/api/user/new/login', logindata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {
                        swal("", "You Have Succesfully Logged In.", "success");
                        localStorage.setItem('useremail', res.data.output.em);
                        localStorage.setItem('userid', res.data.output.id);
                        localStorage.setItem('userphone', res.data.output.ph);
                        localStorage.setItem('username', res.data.output.un);


                        window.location.reload(false);
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal(res.data.msg);
                    }



                })
        }
        else
            this.validator.showMessages();
        this.forceUpdate();
    }
    handleRegisterSubmit = event => {

        event.preventDefault();

        if (this.registervalidator.allValid()) {
            if (this.state.password != this.state.confirmpassword) {
                swal("", "Passwords do not match.", "info");
            }
            else {
                let registerdata = new FormData();    //registerdata object
                registerdata.append('av', Constants.av);
                registerdata.append('pt', Constants.pt);
                registerdata.append('did', Constants.did);
                registerdata.append('mobile', this.state.mobile);
                registerdata.append('password', this.state.password);
                registerdata.append('email', this.state.email);
                registerdata.append('name', this.state.name);

                axios.post(Constants.api + '/api/user/new/register', registerdata)
                    .then(res => {
                        console.log(res);
                        if (res.data.result == 'success') {
                            if (res.data.output.otp_require == 'true') {
                                swal("Please Enter OTP Received on your Mobile Number.")
                                this.setState({ showregister: 0, otpshow: 1 });
                            }
                            //     localStorage.setItem('useremail', res.data.output.em);
                            //     localStorage.setItem('userid', res.data.output.id);
                            //     localStorage.setItem('userphone', res.data.output.ph);
                            //     localStorage.setItem('username', res.data.output.un);
                            //     //   sessionStorage.setItem('loginid',res.data.output.id);
                            //     //   sessionStorage.setItem('logincompany',res.data.output.company);
                            //     //   sessionStorage.setItem('loginname',res.data.output.name);
                            //     //   sessionStorage.setItem('login',res.data.output.name);
                            //     //  // alert("Success");
                            //     //  this.setState({alert:"You have logged in. Redirecting you to the pnanel."});
                            //     //  this.setState({logout:false});

                            //     window.location.reload(false);
                        }
                        else {
                            // this.setState({alert:res.data.msg});
                            swal("", res.data.msg, "info");
                        }

                        // if (res.data.msg == 'Please enter otp')
                        // window.location = '/verifyotp';

                    })
            }

        }
        else
            this.registervalidator.showMessages();
        this.forceUpdate();
    }

    handleotpSubmit = event => {

        event.preventDefault();
        if (this.otpvalidator.allValid()) {

            let otpdata = new FormData();    //otpdata object
            otpdata.append('av', Constants.av);
            otpdata.append('pt', Constants.pt);
            otpdata.append('mobile', this.state.mobile);
            otpdata.append('otp', this.state.otp);
            otpdata.append('name', this.state.name);

            axios.post(Constants.api + '/api/user/verifyotp', otpdata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {

                        localStorage.setItem('useremail', res.data.output.em);
                        localStorage.setItem('userid', res.data.output.id);
                        localStorage.setItem('userphone', res.data.output.ph);
                        localStorage.setItem('username', res.data.output.un);
                        swal("", "You have logged in!", "success");

                        window.location.reload(false);
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal("", res.data.msg, "info");
                    }

                    // if (res.data.msg == 'Please enter otp')
                    // window.location = '/verifyotp';

                })
        }
        else
            this.otpvalidator.showMessages();
        this.forceUpdate();
    }
    handleresetpassSubmit = event => {

        event.preventDefault();
        if (this.resetpassvalidator.allValid()) {
            if (this.state.password != this.state.confirmpassword) {
                swal("", "Passwords do not match.", "info");
            }
            else {
                let resetdata = new FormData();    // resetdata object
                resetdata.append('av', Constants.av);
                resetdata.append('pt', Constants.pt);
                resetdata.append('did', Constants.did);
                resetdata.append('mobile', this.state.mobile);
                resetdata.append('password', this.state.password);
                resetdata.append('authcode', this.state.otp);
                resetdata.append('email', '');

                axios.post(Constants.api + '/api/user/resetpassword', resetdata)
                    .then(res => {
                        console.log(res);
                        if (res.data.result == 'success') {

                            localStorage.setItem('useremail', res.data.output.em);
                            localStorage.setItem('userid', res.data.output.id);
                            localStorage.setItem('userphone', res.data.output.ph);
                            localStorage.setItem('username', res.data.output.un);
                            swal("", "You have logged in!", "success");

                            window.location.reload(false);
                        }
                        else {
                            // this.setState({alert:res.data.msg});
                            swal("", res.data.msg, "info");
                        }

                        // if (res.data.msg == 'Please enter otp')
                        // window.location = '/verifyotp';

                    })
            }
        }
        else
            this.resetpassvalidator.showMessages();
        this.forceUpdate();
    }
    resendOtp = () => {
        try {

            let resendData = new FormData();    //formdata object

            resendData.append('pt', Constants.pt);
            resendData.append('mobile', this.state.mobile);

            // const resp = 
            axios.post(Constants.api + '/api/user/new/resendotp', resendData).then(resp => {
                //  casetype array   
                console.log(resp);
                if (resp.data.result === 'success') {

                    swal("", "OTP has been resent on your Mobile Number.", "success");

                } else {

                    swal(resp.data.msg);
                }



            });

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };
    searchhandleChange = event => {
        this.setState({ search: event.target.value });

    }

    otphandleChange = event => {
        this.setState({ otp: event.target.value });

    }
    namehandleChange = event => {
        this.setState({ name: event.target.value });

    }
    emailhandleChange = event => {
        this.setState({ email: event.target.value });

    }
    mobilehandleChange = event => {
        this.setState({ mobile: event.target.value });

    }
    passwordhandleChange = event => {
        this.setState({ password: event.target.value });
    }
    confirmpasswordhandleChange = event => {
        this.setState({ confirmpassword: event.target.value });
    }
    cityChange = event => {
        this.setState({ 'selectedcity': event.target.value });
        localStorage.setItem('selectedcity', event.target.value);
        //   alert(localStorage.getItem('selectedcity'));
        window.location = '/';
    }
    showLocMob = () => {
        if (this.state.showLocMob == 0)
            this.setState({ 'showLocMob': 1 });
        else
            this.setState({ 'showLocMob': 0 });
    }
    showSearchMob = () => {
        if (this.state.showSearchMob == 0)
            this.setState({ 'showSearchMob': 1 });
        else
            this.setState({ 'showSearchMob': 0 });
    }
    registerClick = () => {
        this.setState({ 'showregister': 1, 'showlogin': 0 });
    }
    forgotpassClick = () => {
        this.setState({ 'showforgotpass': 1, 'showlogin': 0 });
    }
    loginClick = () => {

        this.setState({ 'showlogin': 1 });
    }
    loginotpClick = () => {

        this.setState({ 'showlogin': 0, 'showotplogin': 1 });
    }

    nameClick = () => {
        if (this.state.showMenu == 1)
            this.setState({ 'showMenu': 0 });
        else
            this.setState({ 'showMenu': 1 });
    }

    hideMenu = () => {
        setTimeout(() => {
            this.setState({ 'showMenu': 0 });
        }, timeoutLength);

    }
    loginHide = () => {

        this.setState({ 'showlogin': 0 });
    }
    registerHide = () => {
        this.setState({ 'showregister': 0 });
    }
    forgotpassHide = () => {
        this.setState({ 'showforgotpass': 0 });
    }
    resetpassHide = () => {
        this.setState({ 'resetpassshow': 0 });
    }
    otploginHide = () => {
        this.setState({ 'showotplogin': 0 });
    }
    render() {

        const searchRender = this.state.searchArray.map(function (item, i) {
            if (item.t != 'C') {
                let gotoUrl = '#';
                if (item.t == 'T') {
                    gotoUrl = "/cinemasessions/" + localStorage.getItem('selectedcity') + "/" + item.n + "/" + item.mid;
                }
                else if (item.t == 'M') {
                    gotoUrl = '/moviesessions/' + localStorage.getItem('selectedcity') + "/" + item.n + "/" + item.mid + '?language=' + item.l;
                }
                else
                    gotoUrl = '#';
                return (
                    <>

                        <a href={gotoUrl} key={i}  >
                            {item.n}
                        </a>
                    </>
                )
            }
        });
        const citiesDropdownRender = this.state.cities.map(function (item, i) {
            // alert(item.mname);
            let selected = (item.name == localStorage.getItem('selectedcity')) ? "selected"
                : '';

            return (
                <>

                    <option value={item.name} key={i} selected={selected} >
                        {item.name}
                    </option>
                </>
            )
        });

        return (
            <>
                <nav class="navbar navbar-default">
                    <header class="top_navbar">
                        <div class="top-header">
                            <div class="row">
                                <div class="col-sm-3 col-xs-6">
                                    <a href="/"> <div class="_brand_logo_">
                                        <img 
                                        src={app_logo}
                                        // src={require('../assets/logo.png').default} 
                                        alt="logo" />
                                    </div>
                                    </a>
                                </div>
                                <div class="col-sm-3 col-xs-4 visible-xs text-right">
                                    <div class="mobile_view">
                                        <i class="fa fa-search _clr_dark _search_open" onClick={this.showSearchMob} aria-hidden="true"></i>
                                        <i class="fa fa-map-marker _clr_red _location_detecter_open" onClick={this.showLocMob} aria-hidden="true"></i>
                                    </div>

                                </div>
                                <div className="col-xs-2 visible-xs">
                                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>

                                </div>

                                <form class="_custome_form visible-xs">
                                    {(this.state.showSearchMob == 1) ?
                                        <div class="m_search">
                                            <div class="col-sm-5 col-xs-12">
                                                <div class="select-cutom _icon_set _10px">

                                                    <input class="form-control" type="text" name="search" onChange={this.searchhandleChange}
                                                        onKeyUp={this.getsearchmovies} placeholder="Search for Movies, Cinemas"
                                                        autocomplete="off" />
                                                    <i class="fa fa-search _set_abs _clr_dark" aria-hidden="true"></i>
                                                </div>
                                                {(this.state.showseachdropdown == 1) ?
                                                    <div class="dropdown-content">
                                                        {searchRender}
                                                    </div> : ''}
                                            </div>
                                        </div> : ''}
                                    {(this.state.showLocMob == 1) ?
                                        <div class="_m_location">
                                            <div class="col-sm-2 col-xs-12">
                                                <div class="location_detect _10px">
                                                    <div class="select-cutom _icon_set">
                                                        <select class="form-control _icon_set" name="citydropdown" value={localStorage.getItem('selectedcity')} onChange={this.cityChange}>

                                                            {citiesDropdownRender}
                                                        </select>

                                                        <i class="fa fa-map-marker _set_abs _clr_red" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                </form>

                                <form class="_custome_form hidden-xs">
                                    <div class="_abs_pos_mobile _search_none">
                                        <div class="col-sm-5 col-xs-12">
                                            <div class="select-cutom _icon_set _10px">

                                                <input class="form-control" type="text" name="search" onChange={this.searchhandleChange}
                                                    onKeyUp={this.getsearchmovies} placeholder="Search for Movies, Cinemas"
                                                    autocomplete="off" />
                                                <i class="fa fa-search _set_abs _clr_dark" aria-hidden="true"></i>
                                            </div>
                                            {(this.state.showseachdropdown == 1) ?
                                                <div class="dropdown-content">
                                                    {searchRender}
                                                </div> : ''}
                                        </div>
                                    </div>
                                    <div class="_abs_pos_mobile _none_location">
                                        <div class="col-sm-2 col-xs-12">
                                            <div class="location_detect _10px">
                                                <div class="select-cutom _icon_set">
                                                    <select class="form-control _icon_set" name="citydropdown" value={localStorage.getItem('selectedcity')} onChange={this.cityChange}>

                                                        {citiesDropdownRender}
                                                    </select>

                                                    <i class="fa fa-map-marker _set_abs _clr_red" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="col-sm-2 col-xs-12">
                                    <ul class="nav navbar-nav navbar-right _login_detail hidden-xs">
                                        {(localStorage.getItem('username')) ?
                                            <li><a onClick={this.nameClick} onMouseLeave={this.hideMenu} className="username" style={{ pointer: "cursor" }}>
                                                <i class="fa fa-user"></i>  {localStorage.getItem('username')}</a></li>
                                            : <li><a onClick={this.loginClick} style={{ pointer: "cursor" }}>Login/Register</a></li>
                                        } </ul>

                                </div>
                            </div>
                        </div>

                    </header>
                    <div class="container _pos_rel">
                        <div class="navbar-header hidden-xs">
                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        </div>

                        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul class="nav navbar-nav _24_ml">
                                <li ><a href="/nowshowing">Movies <span class="sr-only">(current)</span></a></li>
                                <li><a href={this.state.cinemaUrl}>Cinemas</a></li>
                                {/* <li><a href="#">Events</a></li> */}
                                <li><a href="/offers">Offers</a></li>
                                <li><a href="/advertise">Advertise</a></li>
                                <li><a href="/aboutus">About Us</a></li>
                                <li><a href="/careers">Careers</a></li>
                                {(localStorage.getItem('username')) ?
                                    <li className="visible-xs"><a onClick={this.nameClick} onMouseLeave={this.hideMenu} className="username" style={{ pointer: "cursor" }}>
                                        <i class="fa fa-user"></i>  {localStorage.getItem('username')}</a></li>
                                    : <li className="visible-xs"><a onClick={this.loginClick} style={{ pointer: "cursor" }}>Login/Register</a></li>
                                }
                            </ul>
                        </div>

                        {(this.state.showMenu == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <div class="form_group pos_relative  "><a href="/profile">Profile</a></div>
                                    <div class="form_group pos_relative  "> <a onClick={this.logout} href="#">Logout</a></div>

                                </div>
                            </div>
                            : ''}
                        {(this.state.showlogin == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <span style={{ float: "right", color: "black" }} onClick={this.loginHide} aria-hidden="true">
                                        <i class="fa fa-close"></i></span>
                                    <div class="form_login">
                                        <div class="form_cont"  >
                                            <h4>Login</h4>
                                            <p>Fill in the details below and get <br /> started quickly</p>
                                        </div>
                                        <form onSubmit={this.handleLoginSubmit}>
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Mobile Number</div>
                                                <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                                <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.validator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Password</div>
                                                <input type="password" value={this.state.value} onChange={this.passwordhandleChange} name="password" placeholder="***" />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                            </div>
                                            {this.validator.message('password', this.state.password, 'required|min:6|max:20')}
                                            <a href="#" onClick={this.forgotpassClick} class="forgot_pass">Forgot password?</a>
                                            <div class="text-center">
                                                <button type="submit" class="btn_style">Sign In</button>
                                                <span>or</span>
                                                <button onClick={this.loginotpClick} class="btn_style">Login with OTP</button>

                                            </div>
                                            <div class="register">
                                                <span>Don’t have an account?</span>
                                                <a onClick={this.registerClick} class="_register">Register</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> : ''}
                        {(this.state.showforgotpass == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <span style={{ float: "right", color: "black" }} onClick={this.forgotpassHide} aria-hidden="true">
                                        <i class="fa fa-close"></i></span>
                                    <div class="form_login">
                                        <div class="form_cont"  >
                                            <h4>Forgot Password?</h4>
                                        </div>
                                        <form onSubmit={this.handleForgotpassSubmit}>
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Mobile Number</div>
                                                <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                                <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.forgotvalidator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                            <br />
                                            <div class="text-center">
                                                <button type="submit" class="btn_style">Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div> : ''}
                        {(this.state.showotplogin == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <span style={{ float: "right", color: "black" }} onClick={this.otploginHide} aria-hidden="true">
                                        <i class="fa fa-close"></i></span>
                                    <div class="form_login">
                                        <div class="form_cont"  >
                                            <h4>Login with OTP</h4>
                                        </div>
                                        <form onSubmit={this.handleOtpLoginSubmit}>
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Mobile Number</div>
                                                <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                                <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.otploginvalidator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                            <br />
                                            <div class="text-center">
                                                <button type="submit" class="btn_style">Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div> : ''}
                        {(this.state.showregister == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <span style={{ float: "right", color: "black" }} onClick={this.registerHide} aria-hidden="true">
                                        <i class="fa fa-close"></i></span>
                                    <div class="form_login">
                                        <div class="form_cont"  >
                                            <h4>Register</h4>
                                            <p>Fill in the details below and get <br /> started quickly</p>
                                        </div>
                                        <form onSubmit={this.handleRegisterSubmit}>
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Full Name</div>
                                                <input type="text" name="name" value={this.state.name} onChange={this.namehandleChange} />
                                                <i class="fa fa-user set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.registervalidator.message('name', this.state.name, 'required')}
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Email</div>
                                                <input type="text" name="email" value={this.state.email} onChange={this.emailhandleChange} />
                                                <i class="fa fa-envelope set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.registervalidator.message('email', this.state.email, 'required|email')}
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Mobile Number</div>
                                                <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                                <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.registervalidator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Password</div>
                                                <input type="password" value={this.state.value} onChange={this.passwordhandleChange} name="password" placeholder="***" />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                            </div>
                                            {this.registervalidator.message('password', this.state.password, 'required|min:6|max:20')}
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Confirm Password</div>
                                                <input name="confirmpassword" value={this.state.value} onChange={this.confirmpasswordhandleChange} type="password" placeholder="***" />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                            </div>
                                            {this.registervalidator.message('confirmpassword', this.state.confirmpassword, 'required|min:6|max:20')}
                                            {/* <a href="" class="forgot_pass">Forget password?</a> */}
                                            <br />
                                            <div class="text-center">
                                                <button type="submit" class="btn_style">Sign Up</button>

                                            </div>
                                            {/* <div class="register">
                                                <span>Don’t have an account?</span>
                                                <a href="" class="_register">Register</a>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div> : ''}
                        {(this.state.otpshow == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <div class="form_login">
                                        <div class="form_cont"  >
                                            {/* <h4>Register</h4>
                                            <p>Fill in the details below and get <br /> started quickly</p>*/}
                                        </div>

                                        <form onSubmit={this.handleotpSubmit}>

                                            <div class="form_group pos_relative">
                                                <div class="name_field">Enter OTP</div>
                                                <input name="otp" value={this.state.value} onChange={this.otphandleChange} type="text" />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                            </div>
                                            {this.otpvalidator.message('otp', this.state.otp, 'required|numeric|min:6|max:6')}
                                            <a href="#" onClick={this.resendOtp} class="forgot_pass">Resend OTP</a>
                                            <div class="text-center">
                                                <button type="submit" class="btn_style">Submit OTP</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {(this.state.resetpassshow == 1) ?
                            <div class="over_lay_login">
                                <div class="movies-login-box">
                                    <span style={{ float: "right", color: "black" }} onClick={this.resetpassHide} aria-hidden="true">
                                        <i class="fa fa-close"></i></span>
                                    <div class="form_login">
                                        <div class="form_cont"  >
                                            <h4>Reset Password</h4>
                                        </div>
                                        <form onSubmit={this.handleresetpassSubmit}>
                                            <div class="form_group pos_relative">
                                                <div class="name_field">OTP</div>
                                                <input type="text" name="otp" value={this.state.value} onChange={this.otphandleChange} />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>
                                            </div>
                                            <a href="#" onClick={this.resendOtp} class="forgot_pass">Resend OTP</a>

                                            {this.resetpassvalidator.message('otp', this.state.otp, 'required|min:6|max:6|numeric')}
                                            <br />
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Password</div>
                                                <input type="password" name="password" value={this.state.value} onChange={this.passwordhandleChange} />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.resetpassvalidator.message('password', this.state.password, 'required|min:6')}
                                            <div class="form_group pos_relative">
                                                <div class="name_field">Confirm Password</div>
                                                <input type="password" name="confirmpassword" value={this.state.value} onChange={this.confirmpasswordhandleChange} />
                                                <i class="fa fa-lock set_abs" aria-hidden="true"></i>
                                            </div>
                                            {this.resetpassvalidator.message('confirmpassword', this.state.confirmpassword, 'required|min:6')}
                                            <br />
                                            <div class="text-center">
                                                <button type="submit" class="btn_style">Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div> : ''}
                    </div>
                </nav>
            </>);
    }
}

export default Header;
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import Header from './Components/Header';
import Home from './Components/Home';
import MovieSession from './Components/MovieSession';
import CinemaSession from './Components/CinemaSession';
import Upcoming from './Components/Upcoming';
import Nowshowing from './Components/NowShowing';
import SeatLayout from './Components/SeatLayout';
import Food from './Components/Food';
import FoodOnSeat from './Components/FoodOnSeat';
import Payment from './Components/Payment';
import ConfirmTck from './Components/ConfirmBooking';
import Cinema from './Components/Cinema';
import UpcomingCinema from './Components/UpcomingCinema';
import Faq from './Components/Faq';
import Tnc from './Components/Tnc';
import Profile from './Components/Profile';
import Offers from './Components/Offers';
import Privacypolicy from './Components/PrivacyPolicy';
import Aboutus from './Components/Aboutus';
import ContactUs from './Components/ContactUs';
import BulkBooking from './Components/BulkBooking';
import PrivateScreening from './Components/PrivateScreening';
import Advertise from './Components/Advertise';
import Apple from './Components/Apple';
import PaymentCheck from './Components/PaymentCheck';
import CancelTrans from './Components/CancelTrans';
import BookingPage from './Components/BookingPage';
import Careers from './Components/Careers';
import CareerDetail from './Components/CareerDetail';
import PageNotFound from './Components/PageNotFound';

class Routes extends React.Component {
    render() {
        const isMobile = window.matchMedia('(max-width: 575px)').matches;
        return (
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/booking/:id' component={Home} />
                <Route exact path='/moviesessions/:city/:moviename/:amcode' component={MovieSession} />
                <Route exact path='/cinemasessions/:city/:cinemaname/:cid' component={CinemaSession} />
                <Route exact path='/nowshowing' component={Nowshowing} />
                <Route exact path='/upcoming' component={Upcoming} />
                <Route exact path='/cinema/:city' component={Cinema} />
                <Route exact path='/upcomingcinema/:city' component={UpcomingCinema} />
                <Route exact path='/faq' component={Faq} />
                <Route exact path='/tnc' component={Tnc} />
                <Route exact path='/apple-app-site-association' component={Apple} />
                <Route exact path='/careers' component={Careers} />
                <Route exact path='/careerdetail/:department/:id' component={CareerDetail} />
                <Route exact path='/contactus' component={ContactUs} />
                <Route exact path='/bulkbooking' component={BulkBooking} />
                <Route exact path='/privatescreening' component={PrivateScreening} />
                <Route exact path='/advertise' component={Advertise} />
                <Route exact path='/offers' component={Offers} />
                <Route exact path='/privacy-policy' component={Privacypolicy} />
                <Route exact path='/aboutus' component={Aboutus} />
                <Route exact path='/booking-page' component={BookingPage} />
                <Route exact path='/profile' component={Profile} />
                <Route exact path='/seatlayout/:cinemaname/:moviename' component={SeatLayout} />
                <Route exact path='/food/:cinemaname/:moviename' component={Food} /> 
                {isMobile ? <Route exact path='/food/:cinemacode/:audino/:seatno' component={FoodOnSeat} /> : <Route exact path='/' component={Home} />}
                <Route exact path='/payment/:cinemaname/:moviename' component={Payment} />
                <Route exact path='/booking' component={ConfirmTck} />
                <Route exact path='/movie/booking/paytmex' component={PaymentCheck} />
                <Route exact path='/cancel/trans' component={CancelTrans} />

                <Route path="*" component={PageNotFound} />

            </Switch>
        )
    }
}

export default Routes;
import React, { Component } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import { Modal, Button, Form } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Redirect } from 'react-router-dom';

const options = {
    items: 4,
};
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nowShowing: [],
            upcoming: [],
            banner: [],
            genres: [],
            loading: 1,
            movietitle: '',
            videoUrl: ''
        }
    }

    componentDidMount() {
        if (localStorage.getItem('selectedcity') == null || !localStorage.getItem('selectedcity')) {
            localStorage.setItem('selectedcity', 'Delhi-NCR')
        }
        if (localStorage.getItem('userid') == null || !localStorage.getItem('userid')) {
            localStorage.setItem('userid', 0)
        }
        localStorage.removeItem('ccode');
        localStorage.removeItem('mcode');
        localStorage.removeItem('bookingid');
        localStorage.removeItem('transid');
        localStorage.removeItem('mname');
        localStorage.removeItem('cname');
        localStorage.removeItem('showDate');
        let bannerData = new FormData();    //formdata object
        bannerData.append('city', localStorage.getItem('selectedcity'));   //append the values with key, value pair
        bannerData.append('av', Constants.av);
        bannerData.append('pt', Constants.pt);
        bannerData.append('type', Constants.pt);
        const bannerRequest = async () => {

            try {
                const resp = await axios.post(Constants.api + '/api/content/bannersnew', bannerData).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {

                        console.log(resp.data.output);
                        let bannerArray = []
                        Object.keys(resp.data.output).forEach(function (key) {
                            bannerArray.push(resp.data.output[key])
                        });

                        this.setState({
                            banner: bannerArray,
                            loading: 0
                        })
                        // console.log('hello');
                        // console.log(this.state.nowShowing);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        );

                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };


        let formDataNS = new FormData();    //formdata object
        formDataNS.append('city', localStorage.getItem('selectedcity'));   //append the values with key, value pair
        formDataNS.append('av', Constants.av);
        formDataNS.append('pt', Constants.pt);
        formDataNS.append('did', Constants.did);
        formDataNS.append('userid', localStorage.getItem('userid'));
        formDataNS.append('dtmsource', 'local');
        formDataNS.append('mobile', '');

        const nowShowingRequest = async () => {

            try {
                const resp = await axios.post(Constants.api + '/api/content/nowshowing', formDataNS).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        let nowShowingArray = []
                        Object.keys(resp.data.output).forEach(function (key) {
                            nowShowingArray.push(resp.data.output[key])
                        });

                        this.setState({
                            nowShowing: nowShowingArray,
                        })
                        console.log('now showing');
                        console.log(this.state.nowShowing);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        let formDataUP = new FormData();    //formdata object
        formDataUP.append('city', localStorage.getItem('selectedcity'));   //append the values with key, value pair
        formDataUP.append('av', Constants.av);
        formDataUP.append('pt', Constants.pt);
        formDataUP.append('did', Constants.did);
        formDataUP.append('userid', localStorage.getItem('userid'));
        formDataUP.append('lang', 'ALL');

        const upcomingRequest = async () => {

            try {
                const resp = await axios.post(Constants.api + '/api/content/comingsoon', formDataUP).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        let upcomingArray = []
                        Object.keys(resp.data.output).forEach(function (key) {
                            upcomingArray.push(resp.data.output[key])
                        });

                        this.setState({
                            upcoming: upcomingArray,
                        })
                        console.log('hello');
                        console.log(this.state.upcoming);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                    }
                    //movie array
                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        upcomingRequest();
        bannerRequest();
        nowShowingRequest();



    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }

        const trailerShow = (title, url) => {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            let videoUrl = "https://www.youtube.com/embed/" + video_id;
            this.setState({ setShow: 1, movietitle: title, videoUrl: videoUrl });
        }
        const closetrailer = () => {
            this.setState({ setShow: 0 });
        }
        // if(this.state.banner.length>0)
        // {

        const bannerRender = this.state.banner.map(function (item, i) {

            return (
                <>
                    {(item.url != '') ?
                        <a href={item.url} target="_blank">
                            <div>
                                <img src={item.i} />

                            </div>
                        </a> : <div>
                            <img src={item.i} />

                        </div>}
                </>
            )
        });
        // }
        // else{
        //     const bannerRender = '';
        // }
        const nowShowingRender = this.state.nowShowing.map(function (item, i) {
            // alert(item.mname);
            let nameForUrl = item.n.toString().toLowerCase().replaceAll(' ', '-').replaceAll('/', '');

            let bookUrl = '/moviesessions/' + localStorage.getItem('selectedcity') + '/' + nameForUrl + '/' + item.id + '?language=' + item.lng;
            return (
                <>

                    <React.Fragment key={item.id}>
                        <div class="col-sm-3 col-xs-12">
                            <div class="movies_box_showing _radius_5px">
                                <div class="movie_poster">
                                    <div class="moives_img_showing _radius_5px _set_like">
                                        {(item.rt != '') ?
                                            <div class="new_release">New Release</div> : ''}
                                        <img class="img-responsive" src={item.miv} alt="" />
                                        {/* <i class="fa fa-heart-o abs_icon _clr_white" aria-hidden="true"></i> */}
                                        <div class="movies_name_and_format_showing">
                                            <h4>{item.n}</h4>
                                            <div class="_eng_showing">
                                                <span>{item.ce}</span>
                                                <span>-</span>
                                                <span>{item.mfs ? item.mfs.join(',') : ''}</span>
                                            </div>
                                            <div class="book_now_showing">
                                                <a href={bookUrl}><button class="BookNow_showing">Book Now</button></a>
                                                {(item.videoUrl) ? <i class="fa fa-play _floa_right play_showing" onClick={() => trailerShow(item.name, item.videoUrl)} aria-hidden="true"></i> : ''}
                                            </div>
                                        </div>
                                    </div>      </div>  </div></div>
                    </React.Fragment>
                </>
            )
        }
        );

        const upcomingRender = this.state.upcoming.map(function (item, i) {
            // alert(item.mname);
            // let nameForUrl = item.name.toString().toLowerCase().replaceAll(' ', '-');

            // let bookUrl = '/moviesessions/Delhi-NCR/' + nameForUrl + '/' + item.masterMovieId + '?language=' + item.lng;
            if (i < 4) {
                return (
                    <>
                        <React.Fragment key={item.masterMovieId}>
                            {/*         <div class="col-sm-3 col-xs-12">
            <div class="movies_box_showing _radius_5px">
            <div class="movie_poster">
              <div class="moives_img_showing _radius_5px _set_like">
                <img class="img-responsive" src={item.miv} />
                {(item.genre.length > 0) ?
                                                <div class="movies_poster_buttom">
                                                    <span class="_btn_list" href="javascript:void(0)">{item.genre}</span>
                                                </div> : ''}
              <div class="movies_name_and_format_showing">
                <h4>{item.name}</h4>
                <div class="_eng_showing">
                <span>{item.censor}</span>
                <span>-</span>
                <span>{item.language}</span>
              </div>
              <div class="book_now">
              <span style={{color:"white"}} >{item.date_caption}</span>
              
                {(item.videoUrl) ?
                                            <i class="fa fa-play _floa_right play_showing" onClick={() => trailerShow(item.name, item.videoUrl)} aria-hidden="true"></i>
                                            : ''}
              </div>
            </div>
              </div>
            </div>
          </div>
          </div> */}
                            <div class="col-sm-3 col-xs-12">
                                <div class="movies_box_showing _radius_5px">
                                    <div class="movie_poster">
                                        <div class="moives_img_showing _radius_5px _set_like">

                                            {/* <div class="new_release">{item.date_caption}</div>  */}
                                            <img class="img-responsive" src={item.miv} alt="" />
                                            {/* <i class="fa fa-heart-o abs_icon _clr_white" aria-hidden="true"></i> */}
                                            <div class="movies_name_and_format_showing">
                                                <h4>{item.name}</h4>
                                                <div class="_eng_showing">
                                                    <span>{item.censor}</span>
                                                    <span>-</span>
                                                    <span>{item.lng}</span>
                                                </div>

                                                <div class="book_now_showing">
                                                    {(item.videoUrl) ? <button class="BookNow_showing" onClick={() => trailerShow(item.name, item.videoUrl)}> Play Trailer</button>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>      </div>  </div></div>
                        </React.Fragment>
                    </>
                )
            }
        });


        return (
            <>
                <Header />





                <Carousel
                    showArrows={true}
                    showStatus={false}
                    showThumbs={false}
                    autoPlay={true}
                    interval={3000}
                    infiniteLoop={true}
                    class="slider_banner slider_overlay">
                    {bannerRender}

                </Carousel>
                {/* Now showing */}
                <section class="_movies_listing">
                    <div class="container">
                        <div class="_head_title" >
                            <h3 class="_title_movies">Now Showing</h3>
                            <div class="col-sm-7 col-xs-12" style={{ display: "none" }}>
                                <div class="row">
                                    <div class="tab_list_movies">
                                        <ul class="nav nav-tabs">
                                            <li class="active"><a data-toggle="tab" href="#all">All</a></li>
                                            <li><a data-toggle="tab" href="#adventure">Adventure</a></li>
                                            <li><a data-toggle="tab" href="#action">Action</a></li>
                                            <li><a data-toggle="tab" href="#comedy">Comedy</a></li>
                                            <li><a data-toggle="tab" href="#thriller">Thriller</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5 col-xs-12" style={{ display: "none" }}>
                                <form class="_custome_form _15px_ _floa_right">
                                    <div class="format_movies">
                                        <div class="location_detect">
                                            <div class="form-group select-cutom _icon_set _bg_gray">
                                                <select class="form-control" name="department" required="">
                                                    <option value="">Format</option>
                                                </select>
                                                <img src={require("../assets/format_icon.svg").default} class="_set_abs" />                  </div>
                                        </div>
                                    </div>
                                    <div class="format_movies">
                                        <div class="location_detect">
                                            <div class="form-group select-cutom _icon_set _bg_gray">
                                                <select class="form-control" name="department" required="">
                                                    <option value="">Language</option>
                                                </select>
                                                <img src={require("../assets/global.svg").default} class="_set_abs" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="tab-content">
                                <div id="all" class="tab-pane fade in active">



                                    {nowShowingRender}
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                {/* Upcoming */}
                {(this.state.upcoming.length != 0) ?
                    <section class="_movies_listing">
                        <div class="container">
                            <div class="_head_title">
                                <h3 class="_title_movies">Upcoming Movies</h3>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row">
                                <div class="tab-content">
                                    <div id="all" class="tab-pane fade in active">
                                        {upcomingRender}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="text-center _pb_30px">
                            <a href='/upcoming' class="BookNow">View All<img class="_left_15px" src={require('../assets/left-arrow.svg').default} alt="" />
                            </a>
                        </div>
                    </section> : ''}
                <Footer />
                <Modal show={this.state.setShow} animation={false}>
                    <Modal.Header closeButton onClick={closetrailer}>
                        <Modal.Title>{this.state.movietitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <><iframe width="100%" height="400"
                            src={this.state.videoUrl}>
                        </iframe></>
                    </Modal.Body>
                    <Modal.Footer>
                        <img src={require('../assets/logo.png').default} alt="" width="200" />
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Home;
import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import Loading from './Loading';
import { Modal, Button, Form } from "react-bootstrap";

class UpcomingCinema extends Component {

  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: '',
    lang: 'ALL',
    format: 'ALL',
    price: 'ALL',
    time: 'ALL',
    cinetype: 'ALL',
    special: 'ALL',
    hc: 'ALL',
    output: {},
    lngs: [],
    cinemas: [],
    days: [],
    search: '',
 }
  componentDidMount() {
    if (!localStorage.getItem('userid'))
      localStorage.setItem('userid', 0);
    let formData = new FormData();    //formdata object //append the values with key, value pair
    formData.append('av', Constants.av);
    formData.append('pt', Constants.pt);
    formData.append('city', localStorage.getItem('selectedcity'));
    const sendGetRequest = async () => {

      try {
        const resp = await axios.post(Constants.api + '/api/content/upcinemas', formData).then(resp => {
          //  casetype array   

          if (resp.data.result == 'success') {
            this.setState(
              {
                loading: 0
              }
            );

           
            let cinemasArray = []
            Object.keys(resp.data.output.c).forEach(function (key) {
              cinemasArray.push(resp.data.output.c[key])
            });

            this.setState({
              cinemas: cinemasArray,
            })
            console.log(this.state.cinemas);
          } else {
            console.log(resp.data.msg);
            this.setState(
              {
                loading: 1
              }
            );
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    sendGetRequest();
  }


  render() {
  
    const cinemaRender = this.state.cinemas.map(function (item, i) {
      return (
        <>
          <div class="cinema-box" >
            <div class="box-header">
              <div class="box-title">
                <h4 class="heading">{item.n}</h4>
              </div>
            </div>

            <div class="box-body">
              <ul class="options">
                <li></li>
              </ul>
              <address>{item.ad}</address>
            </div>

            <div class="box-footer">
              <ul class="">
                {/* <a class="BookNow" href="#" style={{ textAlign: "left"}}>Order Snacks</a> */}
              </ul>
            </div>

          </div>


        </>
      )
    });

    return (
      <>
        <Header />
        <>
          <section class="cinema_section">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="cinema_title">Upcoming Cinemas</div>
                </div>
                {/* mobile view of cinema /. */}
                {/* /. */}
                <div class="col-sm-12 col-xs-12">
                  {(this.state.cinemas.length>0)?cinemaRender:'No Upcoming Cinemas in '+localStorage.getItem('selectedcity')}
                  </div>
              


              </div>
            </div>

          </section>
        </>
        <Footer />
      </>
    );
  }
}

export default UpcomingCinema;

import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import '../CSS/booking.css';
// import '../CSS/booking.css';
import axios from "axios";
import swal from "sweetalert";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from "react-router-dom";
import Loading from './Loading';

class ConfirmBooking extends Component {

    constructor(props) {
        super(props);

    }

    state = {
        cinemaname: this.props.match.params.cinemaname,
        moviename: this.props.match.params.moviename,
        tckDetailsOutput:{},
        tckDetails:[],
        amountDetails:[],
        selectSeat:[],
        totalPrice:0,
        ticketBookedSuccessful:0
    }


    componentDidMount() {
        let formDataTckDetails = new FormData();    //formdata object
        formDataTckDetails.append('cinemacode', localStorage.getItem('ccode'));   //append the values with key, value pair
        formDataTckDetails.append('bookingid', localStorage.getItem('bookingid'));
        formDataTckDetails.append('transid', localStorage.getItem('transid'));
        formDataTckDetails.append('doreq', false);
        formDataTckDetails.append('userid', localStorage.getItem('userid'));
        formDataTckDetails.append('av', Constants.av);
        formDataTckDetails.append('pt', Constants.pt);
        formDataTckDetails.append('did', Constants.did);

        const tckDetailsRequest = async () => {
            try {
                console.log(this.state.moviename);
                console.log(this.state.mcode);
                // const resp = 
                await axios.post(Constants.api + '/api/trans/tckdetails/', formDataTckDetails).then(resp => {
                    //  casetype array   
                    if (resp.data.result === 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        this.setState({
                            tckDetailsOutput: resp.data.output,
                            selectSeat: resp.data.output.seat
                        })

                        let tckDetailsArray = []
                        Object.keys(resp.data.output.f).forEach(function (key) {
                            tckDetailsArray.push(resp.data.output.f[key])
                        });

                        let amountDetailsArray = []
                        Object.keys(resp.data.output.ca).forEach(function (key) {
                            amountDetailsArray.push(resp.data.output.ca[key])
                        });

                        this.setState({
                            tckDetails: tckDetailsArray,
                            totalPrice: resp.data.output.a,
                            amountDetails:amountDetailsArray
                        })
                     

                       

                    } else {
                        console.log(resp.data.msg);
                        swal(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        )
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        let tb = new FormData();    //formdata object //append the values with key, value pair
        tb.append('bookingid', localStorage.getItem('bookingid'));
        tb.append('booktype', 'BOOKING');
        tb.append('userid', localStorage.getItem('userid'));
        tb.append('av', Constants.av);
        tb.append('pt', Constants.pt);
        tb.append('did', Constants.did);
        const ticketBooked = async () => {
            try {
                // const resp = 
                await axios.post(Constants.api + '/api/trans/ticketBooked/', tb).then(resp => {
                    //  casetype array   
                    if (resp.data.result === 'success') {
                        console.log(resp.data.output);
                        this.setState(
                            {
                                ticketBookedSuccessful:1,
                                pqr:resp.data.output.pqr
                            }
                        );
                      
                        

                       

                    } else {
                        console.log(resp.data.msg);
                        swal(resp.data.msg);
                       
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        tckDetailsRequest();
        ticketBooked();
    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }
        const selectSeatRender = this.state.selectSeat.map(function (item, i) {
            return (
                <>
                    <React.Fragment key={i}>
                        <li>{item.n}</li>
                    </React.Fragment>
                </>
            )
        });
        const tckDetailsRender = this.state.tckDetails.map(function (tck, i) {
            return (
                <>
                    <React.Fragment key={i}>
                    {
                                    tck.it.map((det, j) => {
                                            return (
                                                <li>
                                                <div class="_food_item_name">{det.n}</div>
                                                <div class="_food_item_name">{(det.n.startsWith('GST Number'))?'':'INR'} {det.v}</div>
                                            </li>
                                            )
                                    })
                                }
                    </React.Fragment>
                </>
            )
        });
        const amountDetailsRender=  this.state.amountDetails.map(function (tck, i) {
            return (
              <>
                                                <li key={i}>
                                                <div class="_food_item_name">{tck.n}</div>
                                                <div class="_food_item_name"> {tck.v}</div>
                                            </li>
                                            
                                 
                </>
            )
        });

        const backButtonClicked = () => {
            this.props.history.goBack();
        }

        return (
            <>
                {/* <Header /> */}
              <Header/>
                <section class="foods_section">
                    <div class="container">
                        <div class="row">
                        <div class="col-sm-8 col-xs-12">
           <div class="booking_confirmation text-center _120px_m">
             <div class="booking_successfully">
               <img src={require('../assets/checked.png').default} alt="" />
               <h5>Ticket Booked <br /> Successfully!</h5>
               <p>Enjoy your movie! E-ticket has been shared on your registered email ID and phone number</p>
             </div>
             <div class="button_download_and_share booking_mobile">
                <a href="/" class="btn btn_outline btn_text"> 
                  Go Back! </a>
                
                {/* <button class="btn btn_blue btn_text">Share &nbsp;&nbsp;<i class="fa fa-share-alt" aria-hidden="true"></i>
                </button> */}
            </div>
           </div>
        </div>
                            

                            <div class="col-sm-4 col-xs-12 ">
                                <div class="sticky-right">
                                    <div class="booking_summary _35px">
                                        <h3 class="_summary_txt">Booking Summary</h3>
                                        <img class="img-responsive" src={this.state.tckDetailsOutput.imh} alt="" />
                                        <div class="_summary_details _border__">
                                            <div class="_item_list">
                                                <p class="_name__">Movie</p>
                                                <h4 class="movie_name__">{this.state.tckDetailsOutput.m} {this.state.tckDetailsOutput.cen} {this.state.tckDetailsOutput.lg}</h4>
                                            </div>
                                            <div class="_item_list">
                                                <p class="_name__">Location</p>
                                                <h4 class="movie_name__">{this.state.tckDetailsOutput.c}</h4>
                                            </div>

                                            <div class="_item_list">
                                                <p class="_name__">Date</p>
                                                <h4 class="movie_name__"> {this.state.tckDetailsOutput.md}</h4>
                                            </div>

                                            <div class="_item_list">
                                                <p class="_name__">Time</p>
                                                <h4 class="movie_name__">{this.state.tckDetailsOutput.t}</h4>
                                            </div>
                                            <div class="_item_list">
                                                <p class="_name__">Audi</p>
                                                <h4 class="movie_name__">{this.state.tckDetailsOutput.audi}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ticket_prints _35px">
                                        <span class="_name__">Seat Info</span>
                                        <span class="_info__ _float_right">
                                            <img src={require('../assets/information.png').default} />
                                        </span>
                                        <div class="_print_tickets">
                                            <ul class="seats_checkout_ticket">
                                                {selectSeatRender}
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="foods_details_with_coupon _border_top">
                                    <div class="_qrcode__">
                                        <div class="qrcode"><img src={require('../assets/QRcode.png').default} alt="" /></div>
                                        <div class="qrcode">
                                            <div class="scan_text">Scan QR code at the entrance of the cinema hall</div>
                                            <div class="booking_id">Booking ID</div>
                                            <div class="vies_code">{this.state.tckDetailsOutput.bi}</div>
                                        </div>
                                    </div>
                                        <ul class="food_list_selected_">
                                            {tckDetailsRender}
                                            {amountDetailsRender}
                                        </ul>
                                    </div>

                                    <div class="prices_tags">
                                        <div class="ticket_pricing">Total</div>
                                        <div class="ticket_Total_amount">
                                             <i class="fa fa-inr" aria-hidden="true"></i>
                                            <span class="_total_amt">{this.state.totalPrice}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
               


                {/* <Footer /> */}
            </>
        );
    }
}

export default ConfirmBooking;

import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import '../CSS/food.css';
// import '../CSS/booking.css';
import axios from "axios";
import swal from "sweetalert";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from "react-router-dom";
import Loading from './Loading';

class Food extends Component {

    constructor(props) {
        super(props);

    }

    state = {
        cinemaname: this.props.match.params.cinemaname,
        moviename: this.props.match.params.moviename,
        tckDetailsOutput:{},
        tckDetails:[],
        totalPrice:0
    }


    componentDidMount() {
        let formDataTckDetails = new FormData();    //formdata object
        //formDataTckDetails.append('cinemacode', localStorage.getItem('ccode'));   //append the values with key, value pair
        formDataTckDetails.append('bookingid', localStorage.getItem('bookingid'));
        //formDataTckDetails.append('transid', localStorage.getItem('transid'));
        //formDataTckDetails.append('doreq', false);
        /* formDataTckDetails.append('userid', localStorage.getItem('userid')); */
        /* formDataTckDetails.append('av', Constants.av); */
        formDataTckDetails.append('pt', Constants.pt);
        /* formDataTckDetails.append('did', Constants.did); */
        formDataTckDetails.append('booktype', localStorage.getItem("isQrFlow") ? 'FOOD' : "BOOKING");

        const tckDetailsRequest = async () => {
            try {
                console.log(this.state.moviename);
                console.log(this.state.mcode);
                // const resp = 
                await axios.post(Constants.api + '/api/payment/paytmex/status', formDataTckDetails).then(resp => {
                    //  casetype array   
                    if (resp.data.result === 'success' && resp.data.output.p=='PAID') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );

                     window.location = '/booking';
                     console.log(resp.data.result);

                       

                    } else {

                        console.log(resp.data.msg);
                        alert("Transaction Failed");
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        window.location = '/';
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        tckDetailsRequest();
    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }

       

       

        return (
            <>
              
       
            </>
        );
    }
}

export default Food;

import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import '../CSS/food.css';
// import '../CSS/booking.css';
import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from "react-router-dom";
import Loading from './Loading';

class CancelTrans extends Component {

    constructor(props) {
        super(props);

    }

    state = {
        cinemaname: this.props.match.params.cinemaname,
        moviename: this.props.match.params.moviename,
        tckDetailsOutput:{},
        tckDetails:[],
        totalPrice:0
    }


    componentDidMount() {
        let formDataTckDetails = new FormData();    //formdata object
        formDataTckDetails.append('cinemacode', localStorage.getItem('ccode'));   //append the values with key, value pair
        formDataTckDetails.append('bookingid', localStorage.getItem('bookingid'));
        formDataTckDetails.append('transid', localStorage.getItem('transid'));
        formDataTckDetails.append('doreq', false);
        formDataTckDetails.append('userid', localStorage.getItem('userid'));
        formDataTckDetails.append('av', Constants.av);
        formDataTckDetails.append('pt', Constants.pt);
        formDataTckDetails.append('did', Constants.did);
        const cancelTransaction = async () => {
            this.setState({ loading: 1 });
            try {
               
                let paymentData = new FormData();    //formdata object
                paymentData.append('bookingid', localStorage.getItem('bookingid'));
                paymentData.append('transid', localStorage.getItem('transid'));
                paymentData.append('cinemacode', localStorage.getItem('ccode'));
                console.log(this.state.moviename);
                console.log(this.state.mcode);
                // const resp = 
                axios.post(Constants.api + '/api/trans/canceltrans', paymentData).then(resp => {
                    //  casetype array   
                    console.log(resp);
                    window.location = "/";
                    //movie array
    
    
                });
    
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        cancelTransaction();
    }

    render() {
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }

       

       

        return (
            <>
              
       
            </>
        );
    }
}

export default CancelTrans;

import React, { Component } from 'react';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import { Redirect } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

class CareerDetail extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        career: [],
        department: this.props.match.params.department,
        id: this.props.match.params.id,
        whatsappurl: "https://api.whatsapp.com/send?phone=919650130041&text=Hello I want to apply for the job position of " + this.props.match.params.department,
        emailurl: "mailto:hrmanager@movietimecinemas.co.in?subject=Applying for the job position of " + this.props.match.params.department,
        genres: [],
        loading: 1
    }
    componentDidMount() {
        let formDataNS = new FormData(); //formdata object
        formDataNS.append('av', Constants.av);
        formDataNS.append('pt', Constants.pt);
        formDataNS.append('did', Constants.did);

        const careerRequest = async () => {
            try {
                const resp = await axios.post(Constants.api + '/api/career/detail', formDataNS).then(resp => {
                    //  casetype array   
                    if (resp.data.result == 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        let careerArray = []
                        Object.keys(resp.data.output).forEach(function (key) {
                            careerArray.push(resp.data.output[key])
                        });


                        this.setState({
                            career: careerArray,
                        })


                        
                        console.log('hello');
                        console.log(this.state.career);
                    } else {
                        console.log(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
                this.setState(
                    {
                        loading: 1
                    }
                );
            }
        };
        careerRequest();

    }

    render() {
        const cid = this.state.id;
        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }

        const careerRender = this.state.career.map(function (item, i){
            // alert(item.mname);
            if(item.id == cid)
            {


            return (
                <>
                  <div class="col-sm-12 info">
            <div class="row-about">
            </div>
            <div class="row-more">
              <h3>Job Description:</h3>
              <dl class="row">
                <dt class="col-sm-2 left">Age</dt>
                <dd class="col-sm-10 right">{item.age}</dd>
                <dt class="col-sm-2 left">Qualification</dt>
                <dd class="col-sm-10 right">{item.qualification}</dd>
                <dt class="col-sm-2 left">Experience</dt>
                <dd class="col-sm-10 right">{item.experience}</dd>
                <dt class="col-sm-2 left">Description</dt>
                <dd class="col-sm-10 right">{item.desc}</dd>
              </dl>
            </div>            
          </div>
                </>
            )
            }
        });

        return (
            <>
                <Header />
                <header class="jumbotron">
                    <div class="row hero-text">
                        <h1> {this.state.department}</h1>
                      
                    </div>
                </header>
                {/* Now showing */}
                {/* <section class="section-staff">
        <div class="row row-upper">
        <button class="btn btn-default mail"><ion-icon name="mail-outline" class="small-icons"></ion-icon><a  href="mailto:hrmanager@movietimecinemas.co.in">hrmanager@movietimecinemas.co.in</a></button>

                          </div>
                </section> */}
                <section class="section-about">
                    <div class="container">
                 
      {careerRender}
          </div>
                </section>
                <section class="contact">
                    <div class="row contact-content">
                        <h3>Contact Us</h3>
                        <p>Share your resume now</p>

                        <button class="btn btn-default mail"><ion-icon name="mail-outline" class="small-icons"></ion-icon><a href={this.state.emailurl}>hrmanager@movietimecinemas.co.in</a></button>
                        <button class="btn btn-default phone"><ion-icon name="logo-whatsapp" class="small-icons"></ion-icon> <a href={this.state.whatsappurl}>+91 9650130041</a></button>

                    </div>
                </section>
                <Footer />


            </>
        );
    }
}

export default CareerDetail;
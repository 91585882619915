import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import { Modal, Button, Form } from "react-bootstrap";

class Tnc extends Component {

  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: '',
    lang: 'ALL',
    format: 'ALL',
    price: 'ALL',
    time: 'ALL',
    cinetype: 'ALL',
    special: 'ALL',
    hc: 'ALL',
    output: {},
    lngs: [],
    cinemas: [],
    days: []
  }


  render() {


    return (
      <>
        <Header />
        <>
          <>
            <section class="_movies_listing">
              <div class="container">
                <div class="_head_title">
                  <h3 class="_title_movies">Terms & Conditions</h3>
                  <br />
                  <div class="row " style={{ padding: "0 15px 15px 15px" }}>
                    <div class="col-sm-12 col-xs-12">
                      <ul>
                        <li>Tickets once purchased cannot be cancelled, exchanged or refunded.</li>
                        <li>Tickets purchased online are not eligible for discounts, schemes or promotions of any kind.</li>
                        <li>To collect your tickets from the Movie Time Box Office, it is mandatory to present the debit/credit card used by you to book tickets along with the booking confirmation (SMS/Computer printout).</li>
                        <li>Online bookings on our site are carried out over a secure payment gateway.</li>
                        <li>Tickets purchased for a particular multiplex are valid for that multiplex only.</li>
                        <li>Children below the age of 18 years cannot be admitted for movies certified 'A'.</li>
                        <li>Please carry proof of age for movies certified 'A'.</li>
                        <li>Please purchase tickets for children above the age of 3 years.</li>
                        <li>To counter unforeseen delays, please collect your tickets half an hour before show time.</li>
                        <li>Outside food and beverages are not allowed inside the cinema premises.</li>
                        <li>A convenience fee per ticket is levied on all tickets booked online.
              </li>
                        <li>Ticket holders are required to abide by the policies laid down by the MovieTime Management.</li>
                        <li>Entry is allowed only for valid ticket holders.</li>
                        <li>In case a ticket is lost or misplaced, a duplicate ticket cannot be issued.</li>
                        <li>The holder of a physical ticket is deemed to be the owner of the ticket.</li>
                        <li>Decision(s) taken by Movie Time shall be final and binding.</li>
                        <li>Rights of admission reserved.</li>
                        <li>Please add abcd@movietimecinemas.com to your address book to ensure email delivery in your inbox.</li>
                        <li>Entry will be denied for patron/s with any symptom related to COVID-19.</li>
                        <li>All patrons shall be solely responsible and liable for any risks pertaining to the Covid-19 virus upon entry to theatre premises. Movie Time or its Directors, officers, employees or its group companies or insurers and any other related parties shall not be liable directly or indirectly in any manner whatsoever if any patron at any time is infected with the Covid-19 virus or for any injury whether personal or incidental or otherwise and/or for any special, indirect, indirect, punitive, incidental, or consequential damages or any damages whatsoever.</li>
                      </ul></div>
                  </div>
                </div>
              </div>
            </section>
          </>
        </>
        <Footer />
      </>
    );
  }
}

export default Tnc;

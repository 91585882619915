import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import '../CSS/seatlayout.css';
import axios from "axios";
import * as QueryString from "query-string";
import Loading from './Loading';
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';


class SeatLayout extends Component {

    constructor(props) {
        super(props);
        this.otpvalidator = new SimpleReactValidator();
        this.forgotvalidator = new SimpleReactValidator();
        this.resetvalidator = new SimpleReactValidator();
        this.resetpassvalidator = new SimpleReactValidator();
        this.otploginvalidator = new SimpleReactValidator();
        this.registervalidator = new SimpleReactValidator();
        this.validator = new SimpleReactValidator();

    }

    state = {
        cinemaname: this.props.match.params.cinemaname,
        moviename: this.props.match.params.moviename,
        mcode: QueryString.parse(this.props.location.search).mcode,
        ccode: QueryString.parse(this.props.location.search).cinemacode,
        sessionid: QueryString.parse(this.props.location.search).sessionid,
        screenId: QueryString.parse(this.props.location.search).screenId,
        rows: [],
        output: {},
        showNextButton: 0,
        selectedSeats: [],
        ticketPrice: 0,
        prices: [],
        ticketCount: 0,
        transOutput: {},
        seatsToSet: [],
        reserveOutput: {}
    }

    reserveData = {
        transid: '',
        cinemacode: '',
        sessionid: '',
        seats: []
    }

    componentDidMount() {
        let formDataNS = new FormData();    //formdata object
        formDataNS.append('city', localStorage.getItem('selectedcity'));   //append the values with key, value pair
        formDataNS.append('mid', this.state.amcode);
        formDataNS.append('lat', this.state.lat);
        formDataNS.append('lng', this.state.lng);
        formDataNS.append('date', this.state.date);
        formDataNS.append('userid', localStorage.getItem('userid'));
        formDataNS.append('av', Constants.av);
        formDataNS.append('pt', Constants.pt);
        formDataNS.append('did', Constants.did);

        const movieSessionRequest = async () => {
            try {
                console.log(this.state.moviename);
                console.log(this.state.mcode);
                this.setState(
                    {
                        loading: 1
                    }
                );
                // const resp = 
                await axios.post(Constants.api + '/api/trans/getseatlayoutnew/' + this.state.ccode + '/' + this.state.sessionid, formDataNS).then(resp => {
                    //  casetype array   
                    if (resp.data.result === 'success') {
                        this.setState(
                            {
                                loading: 0
                            }
                        );
                        console.log(resp.data.output);
                        console.log(this.state.mname);
                        console.log(this.state.mcode);
                        this.setState({
                            output: resp.data.output
                        })

                        let rowsArray = []
                        Object.keys(resp.data.output.rows).forEach(function (key) {
                            rowsArray.push(resp.data.output.rows[key])
                        });

                        let priceArray = []
                        Object.keys(resp.data.output.priceList).forEach(function (key) {
                            priceArray.push(resp.data.output.priceList[key])
                        });
                        // const AT = resp.data.output.et.toInt() * 60 * 1000 - (resp.data.output.at.toInt() * 60 * 1000);
                        // const ET = resp.data.output.at.toInt() * 60 * 1000;
                        localStorage.setItem('ccode', resp.data.output.cinemaCode);
                        localStorage.setItem('mcode', this.state.mcode);
                        localStorage.setItem('mname', this.state.output.mn.replaceAll('/', ''));
                        localStorage.setItem('cname', resp.data.output.cn);
                        localStorage.setItem('showDate', this.state.output.st);
                        localStorage.removeItem('booktype')
                        localStorage.removeItem('isQrFlow')

                        this.setState({
                            rows: rowsArray,
                            prices: priceArray
                        })
                        console.log(this.state.prices)
                    } else {
                        console.log(resp.data.msg);
                        swal(resp.data.msg);
                        this.setState(
                            {
                                loading: 0
                            }
                        )
                    }
                    //movie array


                });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        movieSessionRequest();

    }

    logout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("useremail");
        localStorage.removeItem("userphone");
        localStorage.setItem("userid", '0');
        swal("", "You have logged out!", "info");
        window.location.reload("/");
    }
    handleForgotpassSubmit = event => {

        event.preventDefault();
        if (this.forgotvalidator.allValid()) {
            let logindata = new FormData();    //logindata object
            logindata.append('pt', Constants.pt);
            logindata.append('av', Constants.av);
            logindata.append('did', Constants.did);
            logindata.append('mobile', this.state.mobile);
            logindata.append('email', '');

            axios.post(Constants.api + '/api/user/forgotpassword', logindata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {
                        if (res.data.msg == 'ok') {
                            swal("Please Enter OTP Received on your Mobile Number.");
                            this.setState({ showforgotpass: 0, resetpassshow: 1 });
                        }
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal(res.data.msg, "info");
                    }



                })
        }
        else
            this.forgotvalidator.showMessages();
        this.forceUpdate();
    }
    handleOtpLoginSubmit = event => {

        event.preventDefault();
        if (this.otploginvalidator.allValid()) {
            let logindata = new FormData();    //logindata object
            logindata.append('pt', Constants.pt);
            logindata.append('mobile', this.state.mobile);

            axios.post(Constants.api + '/api/user/new/resendotp', logindata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {
                        swal("", "Please enter OTP received on your Mobile Number", "info");
                        if (res.data.output.otp_require == 'true') {
                            this.setState({ showotplogin: 0, otpshow: 1 });
                        }
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal(res.data.msg);
                    }



                })
        }
        else
            this.otploginvalidator.showMessages();
        this.forceUpdate();
    }
    handleLoginSubmit = event => {

        event.preventDefault();
        if (this.validator.allValid()) {
            let logindata = new FormData();    //logindata object
            logindata.append('av', Constants.av);
            logindata.append('pt', Constants.pt);
            logindata.append('did', Constants.did);
            logindata.append('mobile', this.state.mobile);
            logindata.append('password', this.state.password);

            axios.post(Constants.api + '/api/user/new/login', logindata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {
                        swal("", "You Have Succesfully Logged In.", "success");
                        localStorage.setItem('useremail', res.data.output.em);
                        localStorage.setItem('userid', res.data.output.id);
                        localStorage.setItem('userphone', res.data.output.ph);
                        localStorage.setItem('username', res.data.output.un);


                        window.location = '/payment/' + this.state.cinemaname + '/' + this.state.moviename;

                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal(res.data.msg);
                    }



                })
        }
        else
            this.validator.showMessages();
        this.forceUpdate();
    }
    handleRegisterSubmit = event => {

        event.preventDefault();

        if (this.registervalidator.allValid()) {
            if (this.state.password != this.state.confirmpassword) {
                swal("", "Passwords do not match.", "info");
            }
            else {
                let registerdata = new FormData();    //registerdata object
                registerdata.append('av', Constants.av);
                registerdata.append('pt', Constants.pt);
                registerdata.append('did', Constants.did);
                registerdata.append('mobile', this.state.mobile);
                registerdata.append('password', this.state.password);
                registerdata.append('email', this.state.email);
                registerdata.append('name', this.state.name);

                axios.post(Constants.api + '/api/user/new/register', registerdata)
                    .then(res => {
                        console.log(res);
                        if (res.data.result == 'success') {
                            if (res.data.output.otp_require == 'true') {
                                swal("Please Enter OTP Received on your Mobile Number.")
                                this.setState({ showregister: 0, otpshow: 1 });
                            }
                            //     localStorage.setItem('useremail', res.data.output.em);
                            //     localStorage.setItem('userid', res.data.output.id);
                            //     localStorage.setItem('userphone', res.data.output.ph);
                            //     localStorage.setItem('username', res.data.output.un);
                            //     //   sessionStorage.setItem('loginid',res.data.output.id);
                            //     //   sessionStorage.setItem('logincompany',res.data.output.company);
                            //     //   sessionStorage.setItem('loginname',res.data.output.name);
                            //     //   sessionStorage.setItem('login',res.data.output.name);
                            //     //  // alert("Success");
                            //     //  this.setState({alert:"You have logged in. Redirecting you to the pnanel."});
                            //     //  this.setState({logout:false});

                            //     window.location.reload(false);
                        }
                        else {
                            // this.setState({alert:res.data.msg});
                            swal("", res.data.msg, "info");
                        }

                        // if (res.data.msg == 'Please enter otp')
                        // window.location = '/verifyotp';

                    })
            }

        }
        else
            this.registervalidator.showMessages();
        this.forceUpdate();
    }

    handleotpSubmit = event => {

        event.preventDefault();
        if (this.otpvalidator.allValid()) {

            let otpdata = new FormData();    //otpdata object
            otpdata.append('av', Constants.av);
            otpdata.append('pt', Constants.pt);
            otpdata.append('mobile', this.state.mobile);
            otpdata.append('otp', this.state.otp);
            otpdata.append('name', this.state.name);

            axios.post(Constants.api + '/api/user/verifyotp', otpdata)
                .then(res => {
                    console.log(res);
                    if (res.data.result == 'success') {

                        localStorage.setItem('useremail', res.data.output.em);
                        localStorage.setItem('userid', res.data.output.id);
                        localStorage.setItem('userphone', res.data.output.ph);
                        localStorage.setItem('username', res.data.output.un);
                        swal("", "You have logged in!", "success");

                        window.location.reload(false);
                    }
                    else {
                        // this.setState({alert:res.data.msg});
                        swal("", res.data.msg, "info");
                    }

                    // if (res.data.msg == 'Please enter otp')
                    // window.location = '/verifyotp';

                })
        }
        else
            this.otpvalidator.showMessages();
        this.forceUpdate();
    }
    handleresetpassSubmit = event => {

        event.preventDefault();
        if (this.resetpassvalidator.allValid()) {
            if (this.state.password != this.state.confirmpassword) {
                swal("", "Passwords do not match.", "info");
            }
            else {
                let resetdata = new FormData();    // resetdata object
                resetdata.append('av', Constants.av);
                resetdata.append('pt', Constants.pt);
                resetdata.append('did', Constants.did);
                resetdata.append('mobile', this.state.mobile);
                resetdata.append('password', this.state.password);
                resetdata.append('authcode', this.state.otp);
                resetdata.append('email', '');

                axios.post(Constants.api + '/api/user/resetpassword', resetdata)
                    .then(res => {
                        console.log(res);
                        if (res.data.result == 'success') {

                            localStorage.setItem('useremail', res.data.output.em);
                            localStorage.setItem('userid', res.data.output.id);
                            localStorage.setItem('userphone', res.data.output.ph);
                            localStorage.setItem('username', res.data.output.un);
                            swal("", "You have logged in!", "success");

                            window.location.reload(false);
                        }
                        else {
                            // this.setState({alert:res.data.msg});
                            swal("", res.data.msg, "info");
                        }

                        // if (res.data.msg == 'Please enter otp')
                        // window.location = '/verifyotp';

                    })
            }
        }
        else
            this.resetpassvalidator.showMessages();
        this.forceUpdate();
    }
    resendOtp = () => {
        try {

            let resendData = new FormData();    //formdata object

            resendData.append('pt', Constants.pt);
            resendData.append('mobile', this.state.mobile);

            // const resp = 
            axios.post(Constants.api + '/api/user/new/resendotp', resendData).then(resp => {
                //  casetype array   
                console.log(resp);
                if (resp.data.result === 'success') {

                    swal("", "OTP has been resent on your Mobile Number.", "success");

                } else {

                    swal(resp.data.msg);
                }



            });

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };
    searchhandleChange = event => {
        this.setState({ search: event.target.value });

    }

    otphandleChange = event => {
        this.setState({ otp: event.target.value });

    }
    namehandleChange = event => {
        this.setState({ name: event.target.value });

    }
    emailhandleChange = event => {
        this.setState({ email: event.target.value });

    }
    mobilehandleChange = event => {
        this.setState({ mobile: event.target.value });

    }
    passwordhandleChange = event => {
        this.setState({ password: event.target.value });
    }
    confirmpasswordhandleChange = event => {
        this.setState({ confirmpassword: event.target.value });
    }
    cityChange = event => {
        this.setState({ 'selectedcity': event.target.value });
        localStorage.setItem('selectedcity', event.target.value);
        //   alert(localStorage.getItem('selectedcity'));
        window.location = '/';
    }
    showLocMob = () => {
        if (this.state.showLocMob == 0)
            this.setState({ 'showLocMob': 1 });
        else
            this.setState({ 'showLocMob': 0 });
    }
    showSearchMob = () => {
        if (this.state.showSearchMob == 0)
            this.setState({ 'showSearchMob': 1 });
        else
            this.setState({ 'showSearchMob': 0 });
    }
    registerClick = () => {
        this.setState({ 'showregister': 1, 'showlogin': 0 });
    }
    forgotpassClick = () => {
        this.setState({ 'showforgotpass': 1, 'showlogin': 0 });
    }
    loginClick = () => {

        this.setState({ 'showlogin': 1 });
    }
    loginotpClick = () => {

        this.setState({ 'showlogin': 0, 'showotplogin': 1 });
    }

    nameClick = () => {
        if (this.state.showMenu == 1)
            this.setState({ 'showMenu': 0 });
        else
            this.setState({ 'showMenu': 1 });
    }


    loginHide = () => {

        this.setState({ 'showlogin': 0 });
    }
    registerHide = () => {
        this.setState({ 'showregister': 0 });
    }
    forgotpassHide = () => {
        this.setState({ 'showforgotpass': 0 });
    }
    resetpassHide = () => {
        this.setState({ 'resetpassshow': 0 });
    }
    otploginHide = () => {
        this.setState({ 'showotplogin': 0 });
    }



    setSeats = () => {
        let formDataInitTrans = new FormData();
        formDataInitTrans.append('screenid', this.state.screenId);
        formDataInitTrans.append('av', Constants.av);
        formDataInitTrans.append('pt', Constants.pt);
        formDataInitTrans.append('did', Constants.did);
        try {
            this.setState(
                {
                    loading: 1
                }
            );
            // const resp = 
            axios.post(Constants.api + '/api/trans/initTrans/' + this.state.ccode + '/' + this.state.sessionid, formDataInitTrans).then(resp => {
                //  casetype array   
                if (resp.data.result === 'success') {
                    this.setState(
                        {
                            loading: 0
                        }
                    );
                    console.log(resp.data.output);
                    this.setState({
                        transOutput: resp.data.output
                    })
                    localStorage.setItem('transid', this.state.transOutput.transid);
                    //   Reserve Seats Request
                    {
                        this.reserveData.transid = this.state.transOutput.transid;
                        this.reserveData.cinemacode = this.state.ccode;
                        this.reserveData.sessionid = this.state.sessionid;
                        this.reserveData.seats = this.state.seatsToSet;

                        let formDataReserveSeats = new FormData();
                        console.log(this.reserveData);
                        formDataReserveSeats.append('reserve', JSON.stringify(this.reserveData));
                        formDataReserveSeats.append('av', Constants.av);
                        formDataReserveSeats.append('pt', Constants.pt);
                        formDataReserveSeats.append('did', Constants.did);
                        try {
                            this.setState(
                                {
                                    loading: 1
                                }
                            );
                            axios.post(Constants.api + '/api/trans/reserveseats/', formDataReserveSeats).then(resp => {
                                if (resp.data.result === 'success') {
                                    this.setState(
                                        {
                                            loading: 0
                                        }
                                    );
                                    console.log(resp.data.output);
                                    this.setState({
                                        reserveOutput: resp.data.output
                                    })
                                    localStorage.setItem('bookingid', this.state.reserveOutput.bookingid);
                                    if (resp.data.output.fc == 'true')
                                        window.location = '/food/' + this.state.cinemaname + '/' + this.state.moviename;
                                    else {
                                        if (localStorage.userid == 0) {

                                            this.setState({ "showlogin": 1 });
                                        }
                                        else
                                            window.location = '/payment/' + this.state.cinemaname + '/' + this.state.moviename;

                                    }

                                } else {
                                    console.log(resp.data.msg);
                                    swal(resp.data.msg);
                                    this.setState(
                                        {
                                            loading: 0,
                                            selectedSeats: [],

                                        }

                                    )
                                    window.location.reload(false);
                                }
                            });

                        } catch (err) {
                            console.error(err);
                        }
                    }

                } else {
                    console.log(resp.data.msg);
                    swal(resp.data.msg);
                    this.setState(
                        {
                            loading: 0
                        }
                    )
                }
                //movie array
            });

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }

    }

    render() {

        if (this.state.loading == 1) {
            return (
                <>
                    <Loading />
                </>
            )
        }

        const seatLayoutRender = this.state.rows.map(function (item, i) {
            if (item.t == 'area') {
                return (
                    <>
                        <React.Fragment key={i}>
                            <div class="seats-row">
                                <div class="seats-col _class_lable">
                                    <span class="seats__area">{item.n}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    </>
                )
            }
            if (item.t == 'seats') {
                return (
                    <>
                        <React.Fragment key={i}>
                            <div class="seats-row">
                                <div class="seats-col">
                                    <span class="seat-row-no">{item.n}</span>
                                </div>
                                {
                                    item.s.map((seat, j) => {
                                        if (seat.s == 0) {
                                            return (
                                                <div class="seats-col">
                                                    <span class="seat _hide">{seat.displaynumber}</span>
                                                </div>
                                            )
                                        } else if (seat.s == 1) {
                                            return (
                                                <div class="seats-col">
                                                    <span id={seat.b} onClick={() => selectSeat(seat.b, seat.sn, seat.c)} class="seat current">{seat.displaynumber}</span>
                                                </div>
                                            )
                                        }
                                        else if (seat.s == 2) {
                                            return (
                                                <div class="seats-col">
                                                    <span class="seat disable current">{seat.displaynumber}</span>
                                                </div>
                                            )
                                        }
                                    })
                                }


                                {/* <div class="seats-col">
                                                <span class="seat current"></span>
                                            </div> */}


                                {/* <div class="seats-col">
                                    <span class="seat selected"></span>
                                </div> */}

                                {/* <div class="seats-col">
                                    <span class="seat _hide"></span>
                                </div> */}

                                {/* <div class="seats-col">
                                    <span class="seat"></span>
                                </div> */}

                            </div>
                        </React.Fragment>
                    </>
                )
            }
        });

        const selectSeat = (seatValue, seatNumber, areaNumber) => {
            if (this.state.ticketCount == 10) {
                this.setState({ showNextButton: 1 });
                let tctPrice = this.state.ticketPrice;
                console.log(areaNumber);
                let areaPrice = this.state.output.priceList[areaNumber];
                console.log(areaPrice);
                let tckCount = this.state.ticketCount;

                var element = document.getElementById(seatValue);
                let selectedSeatsArray = this.state.selectedSeats;
                if (selectedSeatsArray.includes(seatNumber) === true) {
                    var index = selectedSeatsArray.indexOf(seatNumber)
                    if (index !== -1) {
                        element.classList.remove("selected");
                        tctPrice -= parseFloat(areaPrice.price);
                        this.setState({ ticketPrice: tctPrice });
                        tckCount--;
                        this.setState({ ticketCount: tckCount });

                        selectedSeatsArray.splice(index, 1);
                        this.state.seatsToSet.splice(index, 1);
                        if (selectedSeatsArray.length == 0) {
                            this.setState({ showNextButton: 0 });
                        }
                    }
                }
                swal('You can only book 10 tickets at a time.');
                return;
            }
            this.setState({ showNextButton: 1 });
            let tctPrice = this.state.ticketPrice;
            console.log(areaNumber);
            let areaPrice = this.state.output.priceList[areaNumber];
            console.log(areaPrice);
            let tckCount = this.state.ticketCount;

            var element = document.getElementById(seatValue);
            let selectedSeatsArray = this.state.selectedSeats;
            if (selectedSeatsArray.includes(seatNumber) === true) {
                var index = selectedSeatsArray.indexOf(seatNumber)
                if (index !== -1) {
                    element.classList.remove("selected");
                    tctPrice -= parseFloat(areaPrice.price);
                    this.setState({ ticketPrice: tctPrice });
                    tckCount--;
                    this.setState({ ticketCount: tckCount });

                    selectedSeatsArray.splice(index, 1);
                    this.state.seatsToSet.splice(index, 1);
                    if (selectedSeatsArray.length == 0) {
                        this.setState({ showNextButton: 0 });
                    }
                }
            } else {
                tctPrice += parseFloat(areaPrice.price);
                this.setState({ ticketPrice: tctPrice });
                tckCount++;
                this.setState({ ticketCount: tckCount });
                element.classList.add("selected");
                selectedSeatsArray.push(seatNumber);
                let seatObj = {};
                seatObj.priceCode = areaPrice.priceCode;
                seatObj.seatBookingId = seatValue;
                this.state.seatsToSet.push(seatObj);
            }

            this.setState({
                selectedSeats: selectedSeatsArray
            })

            console.log(this.state.selectedSeats);
        }

        const selectSeatRender = this.state.selectedSeats.map(function (item, i) {
            return (
                <>
                    <React.Fragment key={i}>
                        <li>{item}</li>
                    </React.Fragment>
                </>
            )
        });

        const backButtonClicked = () => {
            this.props.history.goBack();
        }


        return (
            <>
                {/* <Header /> */}
                {/* <div className="top-header text-center">
                    <img src={require('../assets/safety.png').default} />
                </div> */}
                {/* Your safety strip */}
                <section class="seats_section">
                    <div class="top-header">
                        <div class="row">
                            <div class="col-sm-9 col-xs-12">
                                <div class="seat__title">
                                    {/* <div class="_box_title">
                                        <h3 class="_seat_title">Select Seats</h3>
                                    </div> 
                                    <div class="_box_title text-right _select__">
                                        <form class="_custome_form _15px_ _floa_right">
                                            <div class="format_movies">
                                                <div class="location_detect">
                                                    <div class="form-group select-cutom _icon_set _bg_gray">
                                                        <img src={require('../assets/office-chair.svg').default} class="_set_abs" />
                                                        <span>{this.state.ticketCount} Seats</span>
                                                        <select class="form-control" name="department" required="">
                                                            <option value=""></option>
                                                        </select> 
                                                    </div>
                                                </div>
                                            </div>
                                          <div class="format_movies">
                                                <div class="location_detect">
                                                    <div class="form-group select-cutom _icon_set _bg_gray">
                                                        <select class="form-control" name="department" required="">
                                                            <option value="">1:10 PM</option>
                                                        </select>
                                                        <img src={require('../assets/clock1.svg').default} class="_set_abs" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> */}
                                </div>
                                <div class="cinema-labels" >
                                    <div class="box box-available">AVAILABLE</div>
                                    <div class="box box-selected">SELECTED</div>
                                    <div class="box box-unavailable">NOT AVAILABLE</div>
                                </div>
                                <div class="scree_way"><i class="fa fa-arrow-up" aria-hidden="true"> </i> SCREEN THIS WAY <i class="fa fa-arrow-up" aria-hidden="true"> </i></div>

                                <div class="cinemas_seats">
                                    {seatLayoutRender}

                                </div>




                            </div>
                            <div class="bottom_sticky">
                                <button onClick={backButtonClicked} class="btn btn_outline btn_text"><i class="fa fa-long-arrow-left" aria-hidden="true">&nbsp;&nbsp;</i>
                                    Back</button>
                                {(this.state.showNextButton == 1) ?
                                    <button onClick={this.setSeats} class="btn btn_blue _float_right btn_text">Next &nbsp;&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </button>
                                    : ''}
                            </div>


                        </div>

                    </div>

                </section>

                {/* booking summary */}

                <div class="sticky-right summary-sticky">
                    <div class="booking_summary _35px">
                        <h3 class="_summary_txt">Booking SUmmary</h3>
                        <img class="img-responsive" src={this.state.output.imh} alt="" />
                        <div class="_summary_details _border__">
                            <div class="_item_list">
                                <p class="_name__">Movie</p>
                                <h4 class="movie_name__">{this.state.output.mn}</h4>
                            </div>
                            <div class="_item_list">
                                <p class="_name__">Location</p>
                                <h4 class="movie_name__">{this.state.output.cn}</h4>
                            </div>
                            <div class="_item_list">
                                <p class="_name__">Date</p>
                                <h4 class="movie_name__">{this.state.output.st}</h4>
                            </div>
                            {/* <div class="_item_list">
                                                <p class="_name__">Time</p>
                                                <h4 class="movie_name__">1:10 PM</h4>
                                            </div> */}
                        </div>
                    </div>
                    <div class="ticket_prints _35px">
                        <span class="_name__">Seat Info</span>
                        <span class="_info__ _float_right">
                            <img src={require('../assets/information.png').default} />
                        </span>
                        <div class="_print_tickets">
                            <ul class="seats_checkout_ticket">
                                {selectSeatRender}
                            </ul>
                        </div>
                    </div>
                    <div class="prices_tags">
                        <div class="ticket_pricing">Ticket price</div>
                        <div class="ticket_Total_amount">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            <span class="_total_amt">{this.state.ticketPrice}</span>
                        </div>
                    </div>
                </div>
                {/* booking summary end */}

                {(this.state.showlogin == 1) ?
                    <div class="over_lay_login">
                        <div class="movies-login-box">

                            <div class="form_login">
                                <div class="form_cont"  >
                                    <h4>Login</h4>
                                    <p>Fill in the details below and get <br /> started quickly</p>
                                </div>
                                <form onSubmit={this.handleLoginSubmit}>
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Mobile Number</div>
                                        <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                        <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.validator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Password</div>
                                        <input type="password" value={this.state.value} onChange={this.passwordhandleChange} name="password" placeholder="***" />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                    </div>
                                    {this.validator.message('password', this.state.password, 'required|min:6|max:20')}
                                    <a href="#" onClick={this.forgotpassClick} class="forgot_pass">Forgot password?</a>
                                    <div class="text-center">
                                        <button type="submit" class="btn_style">Sign In</button>
                                        <span>or</span>
                                        <button onClick={this.loginotpClick} class="btn_style">Login with OTP</button>

                                    </div>
                                    <div class="register">
                                        <span>Don’t have an account?</span>
                                        <a onClick={this.registerClick} class="_register">Register</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : ''}
                {(this.state.showforgotpass == 1) ?
                    <div class="over_lay_login">
                        <div class="movies-login-box">
                            <span style={{ float: "right", color: "black" }} onClick={this.forgotpassHide} aria-hidden="true">
                                <i class="fa fa-close"></i></span>
                            <div class="form_login">
                                <div class="form_cont"  >
                                    <h4>Forgot Password?</h4>
                                </div>
                                <form onSubmit={this.handleForgotpassSubmit}>
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Mobile Number</div>
                                        <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                        <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.forgotvalidator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                    <br />
                                    <div class="text-center">
                                        <button type="submit" class="btn_style">Submit</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div> : ''}
                {(this.state.showotplogin == 1) ?
                    <div class="over_lay_login">
                        <div class="movies-login-box">
                            <span style={{ float: "right", color: "black" }} onClick={this.otploginHide} aria-hidden="true">
                                <i class="fa fa-close"></i></span>
                            <div class="form_login">
                                <div class="form_cont"  >
                                    <h4>Login with OTP</h4>
                                </div>
                                <form onSubmit={this.handleOtpLoginSubmit}>
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Mobile Number</div>
                                        <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                        <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.otploginvalidator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                    <br />
                                    <div class="text-center">
                                        <button type="submit" class="btn_style">Submit</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div> : ''}
                {(this.state.showregister == 1) ?
                    <div class="over_lay_login">
                        <div class="movies-login-box">
                            <span style={{ float: "right", color: "black" }} onClick={this.registerHide} aria-hidden="true">
                                <i class="fa fa-close"></i></span>
                            <div class="form_login">
                                <div class="form_cont"  >
                                    <h4>Register</h4>
                                    <p>Fill in the details below and get <br /> started quickly</p>
                                </div>
                                <form onSubmit={this.handleRegisterSubmit}>
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Full Name</div>
                                        <input type="text" name="name" value={this.state.name} onChange={this.namehandleChange} />
                                        <i class="fa fa-user set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.registervalidator.message('name', this.state.name, 'required')}
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Email</div>
                                        <input type="text" name="email" value={this.state.email} onChange={this.emailhandleChange} />
                                        <i class="fa fa-envelope set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.registervalidator.message('email', this.state.email, 'required|email')}
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Mobile Number</div>
                                        <input type="text" name="mobile" placeholder="9971****46" value={this.state.value} onChange={this.mobilehandleChange} />
                                        <i class="fa fa-phone set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.registervalidator.message('mobile', this.state.mobile, 'required|min:10|max:10|numeric')}
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Password</div>
                                        <input type="password" value={this.state.value} onChange={this.passwordhandleChange} name="password" placeholder="***" />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                    </div>
                                    {this.registervalidator.message('password', this.state.password, 'required|min:6|max:20')}
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Confirm Password</div>
                                        <input name="confirmpassword" value={this.state.value} onChange={this.confirmpasswordhandleChange} type="password" placeholder="***" />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                    </div>
                                    {this.registervalidator.message('confirmpassword', this.state.confirmpassword, 'required|min:6|max:20')}
                                    {/* <a href="" class="forgot_pass">Forget password?</a> */}
                                    <br />
                                    <div class="text-center">
                                        <button type="submit" class="btn_style">Sign Up</button>

                                    </div>
                                    {/* <div class="register">
                                                <span>Don’t have an account?</span>
                                                <a href="" class="_register">Register</a>
                                            </div> */}
                                </form>
                            </div>
                        </div>
                    </div> : ''}
                {(this.state.otpshow == 1) ?
                    <div class="over_lay_login">
                        <div class="movies-login-box">
                            <div class="form_login">
                                <div class="form_cont"  >
                                    {/* <h4>Register</h4>
                                            <p>Fill in the details below and get <br /> started quickly</p>*/}
                                </div>

                                <form onSubmit={this.handleotpSubmit}>

                                    <div class="form_group pos_relative">
                                        <div class="name_field">Enter OTP</div>
                                        <input name="otp" value={this.state.value} onChange={this.otphandleChange} type="text" />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>

                                    </div>
                                    {this.otpvalidator.message('otp', this.state.otp, 'required|numeric|min:6|max:6')}
                                    <a href="#" onClick={this.resendOtp} class="forgot_pass">Resend OTP</a>
                                    <div class="text-center">
                                        <button type="submit" class="btn_style">Submit OTP</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    : ''}
                {(this.state.resetpassshow == 1) ?
                    <div class="over_lay_login">
                        <div class="movies-login-box">
                            <span style={{ float: "right", color: "black" }} onClick={this.resetpassHide} aria-hidden="true">
                                <i class="fa fa-close"></i></span>
                            <div class="form_login">
                                <div class="form_cont"  >
                                    <h4>Reset Password</h4>
                                </div>
                                <form onSubmit={this.handleresetpassSubmit}>
                                    <div class="form_group pos_relative">
                                        <div class="name_field">OTP</div>
                                        <input type="text" name="otp" min="6" max="6" value={this.state.value} onChange={this.otphandleChange} />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>
                                    </div>
                                    <a href="#" onClick={this.resendOtp} class="forgot_pass">Resend OTP</a>

                                    {this.resetpassvalidator.message('otp', this.state.otp, 'required|min:6|max:6|numeric')}
                                    <br />
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Password</div>
                                        <input type="password" name="password" value={this.state.value} onChange={this.passwordhandleChange} />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.resetpassvalidator.message('password', this.state.password, 'required|min:6')}
                                    <div class="form_group pos_relative">
                                        <div class="name_field">Confirm Password</div>
                                        <input type="password" name="confirmpassword" value={this.state.value} onChange={this.confirmpasswordhandleChange} />
                                        <i class="fa fa-lock set_abs" aria-hidden="true"></i>
                                    </div>
                                    {this.resetpassvalidator.message('confirmpassword', this.state.confirmpassword, 'required|min:6')}
                                    <br />
                                    <div class="text-center">
                                        <button type="submit" class="btn_style">Submit</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div> : ''}

                {/* <Footer /> */}
            </>
        );
    }
}

export default SeatLayout;

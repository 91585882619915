import React, { Component } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import Loading from "./Loading";
import * as Constants from "../Constants";
import "../App.css";
import { Modal, Button, Form } from "react-bootstrap";
import parse from "html-react-parser";
import defaultOffer from "../assets/offer.jpeg";

class Offers extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    offers: [],
    loading: 1,
  };
  componentDidMount() {
    let formDataNS = new FormData(); //formdata object
    formDataNS.append("av", Constants.av);
    formDataNS.append("pt", Constants.pt);
    formDataNS.append("did", Constants.did);
    const offersRequest = async () => {
      try {
        const resp = await axios
          .post(Constants.api + "/api/deals/mobile", formDataNS)
          .then((resp) => {
            //  casetype array
            if (resp.data.result == "success") {
              this.setState({
                loading: 0,
              });
              console.log(resp.data.output);
              let OffersArray = [];
              Object.keys(resp.data.output).forEach(function (key) {
                OffersArray.push(resp.data.output[key]);
              });

              this.setState({
                offers: OffersArray,
              });
              console.log("hello");
              console.log(this.state.Offers);
            } else {
              console.log(resp.data.msg);
              this.setState({
                loading: 0,
              });
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
        this.setState({
          loading: 1,
        });
      }
    };
    offersRequest();
  }

  render() {
    if (this.state.loading == 1) {
      return (
        <>
          <Loading />
        </>
      );
    }
    const offerShow = (title, detail, img) => {
      detail = parse(detail);
      this.setState({
        setShow: 1,
        offertitle: title,
        offerdetail: detail,
        offerimg: img,
      });
    };
    const closeoffer = () => {
      this.setState({ setShow: 0 });
    };

    const offerRender = this.state.offers.map(function (item, i) {
      // alert(item.mname);
      return (
        <>
          <React.Fragment key={i}>
            <div class="col-sm-3 col-xs-12" key={i}>
              <div
                class="offer_box _radius_5px"
                onClick={() => offerShow(item.t, item.description, item.i2)}
              >
                <div class="movie_poster">
                  <div class=" _radius_5px _set_like">
                    {/* <img class="img-responsive" src={item.wit} alt="" /> */}
                    <img class="img-responsive" src={item.i} alt="" />
                    {/* <i class="fa fa-heart-o abs_icon _clr_white" aria-hidden="true"></i> */}
                  </div>
                  <div class="movies_name_and_format">
                    <h4>{item.t}</h4>
                    <h6>{item.detailText}</h6>
                    <div class="_eng">
                      <span>Valid Till</span>
                      <span>-</span>
                      <span>{item.vt}</span>
                    </div>
                  </div>
                  <div class="book_now">
                    <span>{item.detailTxt}</span>
                  </div>
                  {/* <div class="book_now">{(item.videoUrl) ?
                                            <i class="fa fa-play _floa_right" onClick={() => offerShow(item.name, item.videoUrl)} aria-hidden="true"></i>
                                            : ''}
                                </div> */}
                </div>
              </div>
            </div>
          </React.Fragment>
        </>
      );
    });

    console.log("this.state.offers", this.state.offers.length);

    return (
      <>
        <Header />
        {/* Now showing */}
        <section class="_movies_listing">
          <div class="container">
            <div class="_head_title">
              <h3 class="_title_movies">Offers</h3>
              <div class="col-sm-7 col-xs-12"></div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="tab-content">
                <div id="all" class="tab-pane fade in active">
                  {this.state.offers.length > 0 ? (
                    offerRender
                  ) : (
                    <a
                      href="https://play.google.com/store/apps/details?id=com.movietime"
                      target="_blank"
                    >
                    <div class=" _radius_5px _set_like">
                      <img
                        src={defaultOffer}
                        style={{ marginBottom: "50px" }}
                        alt=""
                        className="img-responsive"
                        // src={require("../assets/offer.jpg").default}
                      />
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Modal show={this.state.setShow} animation={false}>
          <Modal.Header closeButton onClick={closeoffer}>
            <Modal.Title>{this.state.offertitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <img width="100%" src={this.state.offerimg} />
            </>
            {this.state.offerdetail}
          </Modal.Body>
          <Modal.Footer>
            <img
              src={require("../assets/logo.png").default}
              alt=""
              width="200"
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Offers;

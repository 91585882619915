import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import { Modal, Button, Form } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';


class Advertise extends Component {

  constructor(props) {
      super(props);
      this.validator = new SimpleReactValidator();
  }

  state = {

    name:'',
    mobile:'',
    email:'',
    comment:'',
    ctype:'Advertise',

    
  }

  handleSubmit = event => {

    event.preventDefault();
    if (this.validator.allValid()) {
        let feedbackdata = new FormData();    //feedbackdata object
        feedbackdata.append('av', Constants.av);
        feedbackdata.append('pt', Constants.pt);
        feedbackdata.append('did', Constants.did);
        feedbackdata.append('mobile', this.state.mobile);
        feedbackdata.append('email', this.state.email);
        feedbackdata.append('ctype',this.state.ctype);
        feedbackdata.append('comment',this.state.comment);

        axios.post(Constants.api + '/api/contactus', feedbackdata)
            .then(res => {
                if (res.data.result == 'success') {
                  swal("Your enquiry has been succesfully submitted. ","We will get back to you soon.","success");
                  document.getElementById("feedbackform").reset();

                    //window.location.reload(false);
                }
                else {
                    // this.setState({alert:res.data.msg});
                    swal(res.data.msg);
                }

                if (res.data.msg == 'Please enter otp')
                    window.location = '/verifyotp';

            })
    }
    else
        this.validator.showMessages();
    this.forceUpdate();
}
namehandleChange = event => {
  this.setState({ name: event.target.value });

}
emailhandleChange = event => {
  this.setState({ email: event.target.value });

}
mobilehandleChange = event => {
  this.setState({ mobile: event.target.value });

}
namehandleChange = event => {
  this.setState({ name: event.target.value });

}
commenthandleChange = event => {
  this.setState({ comment: event.target.value });

}
  render() {
    if (this.state.loading == 1) {
      return (
          <>
              <Loading />
          </>
      )
  }

    return (
      <>
        <Header />
        <>

          <section class="feedback_section _feed_p">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 col-xs-12">
          <div class="feedback-box">
            <form onSubmit={this.handleSubmit} id="feedbackform" class="feedback_form">
              <div class="col-sm-12 col-xs-12"><h4 class="_title_feed_back">Advertise with Us</h4></div>
              <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                      <input type="text" class="feed_input" value={this.state.value} onChange={this.namehandleChange} name="name" placeholder="Name" />
                    </div>
                    {this.validator.message('name', this.state.name, 'required')}
                </div>
             
              <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                      <input type="text" class="feed_input"  name="mobile" value={this.state.value} onChange={this.mobilehandleChange} placeholder="Mobile" />
                    </div>
                    {this.validator.message('mobile', this.state.mobile, 'required|min:10|max:10')}
              </div>
             
              <div class="col-sm-6 col-xs-12">
                  <div class="form-group">
                      <input type="text" class="feed_input"  value={this.state.value} onChange={this.emailhandleChange} name="email" placeholder="Email" />
                    </div>
                    {this.validator.message('email', this.state.email, 'required|email')}
              </div>
             
              <div class="col-sm-6 col-xs-12"></div>
             
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                    <textarea class="text-area" maxlength="500" rows="4" name="comment" value={this.state.value} onChange={this.commenthandleChange} type="text" placeholder="Message"></textarea>
                </div>
                {this.validator.message('comment', this.state.comment, 'required')}
              </div>
             
              <div class="col-sm-12 col-xs-12">
                  <div class="form-group _send_request">
                      <button class="btn btn_blue btn_text" type="submit">SEND Enquiry</button>
                  </div>
                </div>
               
                <div class="col-sm-6 col-xs-12">
                    {/* <div class="form-group">
                      <span class="phone_feed">Ph: 011 - 2323XXXX</span>  
                    </div> */}
                  </div>
                 
                  <div class="col-sm-6 col-xs-12">
                      <div class="form-group">
                      <a href="mailto:info@movietimecinemas.in"> <span class="email_feed">Email: info@movietimecinemas.in
                            </span></a>
                      </div>
                    </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
    </section>
   
           
        </>
        <Footer />
      </>
    );
  }
}

export default Advertise;

import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import { Modal, Button, Form } from "react-bootstrap";

class Faq extends Component {

  // constructor(props) {
  //     super(props);
  // }

  state = {
    city: this.props.match.params.city,
    amcode: this.props.match.params.amcode,
    mname: this.props.match.params.moviename,
    lat: 0.0,
    lng: 0.0,
    date: '',
    lang: 'ALL',
    format: 'ALL',
    price: 'ALL',
    time: 'ALL',
    cinetype: 'ALL',
    special: 'ALL',
    hc: 'ALL',
    output: {},
    lngs: [],
    cinemas: [],
    days: []
  }


  render() {


    return (
      <>
        <Header />
        <>
          <section class="_movies_listing faqs_list">
            <div class="container">
              <div class="_head_title">
                <h3 class="_title_movies">FAQs</h3>
                <br/>
                <div class="col-sm-12 col-xs-12 ">
                  <div className="row">
                    <ol>
                      <li> How can I contact Movie Time Cinemas?</li>
                      <h4>Please feel free to e-mail us at info@movietimecinemas.in for all your queries and suggestions.</h4>
                      <li>Can I book my cinema tickets online?</li>
                      <h4>It is possible to book tickets online for all MovieTime cinemas. Simply log on to www.movietimecinemas.in , click on ’Book Tickets’ and follow the instructions.</h4>
                      <li>Why do I have to pay extra to book tickets through the website?</li>
                      <h4>A nominal fee is added to your transaction to help us to provide you with the convenience of booking tickets without having to reach to the cinema. MOVIETIME incurs considerable expenditure in provisioning of this service and the fee is collected to ensure the service remains reliable at all times. This is a standard industry practice.
            </h4>
                      <li>Is buying tickets on-line on movietimecinemas.in safe and secure?</li>
                      <h4>Online booking service is protected by the latest 128 bit Secure Socket Layer (SSL) security
            </h4>
                      <li>What types of payment are accepted online?</li>
                      <h4>Accepts internet banking along with all major credit and debit cards issued by Visa, MasterCard and AMEX. When you collecting the tickets from Box office /Auto Kiosk, the card holder must be present with the card used to make the booking. Tickets will not be made available to anyone without the credit/ debit card.</h4>
                      <li>What happens if my machine/mobile hangs or restarts or my internet disconnects when I am in the middle of a booking?</li>
                      <h4>Your account might have got debited for the amount of the tickets you were trying to purchase. If this is the case, your amount will automatically be refunded to your Credit Card / Bank account within a maximum of 10 working days. If you do not receive the refund within this period, please contact us on info@movietimecinemas.in

            <br />
            Note – Before you try to book the same tickets again, please do login and check your account history to see if the reservation already exists. If so, you can retrieve your booking from the "my accounts" section .If the booking does not exist (and you have been debited), then your refund will be processed as mentioned above.
            </h4>
                      <li>How do I know if my booking is confirmed?</li>
                      <h4>Confirmation of your booking is clearly displayed on your computer screen at the end of the booking process. You can print this information if you wish, but this is not necessary in order to receive your tickets. In addition, as you have entered an email address, confirmation of your booking will be sent to you via email followed by an SMS on the number given by you while booking the tickets. In order to view your transaction history, click on the “My Account” link on the top right side panel of the website.
            </h4>
                      <li>Once I have booked tickets online, how do I collect them?</li>
                      <h4>You can collect your tickets from the Box Office or ticket kiosk at the cinema you have booked tickets for. Please remember to bring your Credit / Debit card with you ensuring it is the one used to make the purchase. For security reasons, the card holder MUST be present for the tickets to be collected at the cinema.
            </h4>
                      <li>Once I have booked tickets online, when can I collect them?</li>
                      <h4>The tickets may be collected during the cinema's normal opening hours as soon as the booking has been confirmed, any time before the show time. However, it is advisable to be present 25 – 40 minutes before the show time to cushion against last minute delays.
            </h4>
                      <li>If I am running late for the film will my seat be held?</li>
                      <h4>Once your purchase has been confirmed, your admission is held and will not be sold to another customer.
            </h4>
                      <li>Can I cancel the tickets that I have booked online and get a refund?</li>
                      <h4>Tickets once bought cannot be cancelled, refunded or exchanged.</h4>
                      <li>What happens if I forget my password?</li>
                      <h4>It happens to the best of us! Simply select the Sign in button on the top right hand side navigation bar and click on the Forgot Password link. A link to reset your password will be emailed to you automatically to the e-mail ID provided by you during registration.
            </h4>
                      <li>How do I change my password?</li>
                      <h4>The "My Account" section allows you to manage your personal profile details and also change your password. Sign in to your account and select the Change Password tab to change your password. Note – you will need your old password here in order to change your password.
            </h4>
                      {/* <li>What are the benefits of registering on www.movietimecinemas.in (Web Url Need confirmation again)?
            </li>
                      <h4>Registration with movietimecinemas.in ensures you get access to the members section with the convenience of retrieving past booking information, sharing booking details with friends, family, etc.
            </h4> */}
                      <li>How do I book for Birthdays, Kitty Parties & Group Bookings?</li>
                      <h4>The Group bookings link will allow you to request for bulk bookings or for parties, birthdays, etc. Use the simple form to contact us or scroll down to find the contact details of the person in your city
            </h4>
                      <li>How can I find out about the facilities available at the cinema?</li>
                      <h4>Selecting locations under the cinemas tab will provide you with a list of all our cinemas along with the facilities available there. For more information, please write to us at info@movietimecinemas.in.</h4>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
        <Footer />
      </>
    );
  }
}

export default Faq;

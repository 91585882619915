import swal from "sweetalert";

export const showResponceMsg = (severty, msg)=>{
    swal({
        title: severty === 'success' ? 'Success' : 'Oops',
        text: msg,
        timer: 3000,
        icon: severty,
        buttons: false,
      });
}
export const formatDate = (newDate) => {
    const months = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    }
  
    const d = newDate
    const year = d.getFullYear();
    const date0 = d.getDate();
    const date = date0 < 10 ? `0${date0}` : date0;
    const monthIndex = d.getMonth()
    const monthName = months[d.getMonth()]
    const formatted = `${monthName} ${date}, ${year}`
    let finalDate = formatted.toString()
    if (isNaN(monthIndex) === true) {
      finalDate = 'NA'
    }
  
    return finalDate
  }


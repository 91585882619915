import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import * as Constants from '../Constants';
import '../App.css';
import axios from "axios";
import '../CSS/cinema.css';
import { Modal, Button, Form } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import swal from 'sweetalert';
import * as moment from 'moment';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class BulkBooking extends Component {

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.otpvalidator = new SimpleReactValidator();
    this.movieChange = this.movieChange.bind(this);
    this.cinemaChange = this.cinemaChange.bind(this);
  }

  state = {

    name: '',
    mobile: '',
    email: '',
    comment: '',
    type: 'BB',
    seats: 0,
    otpshow:0,
    resend: false,
    otp: '',
    cinema: null,
    bd:null,
    movie: null,
    bookingdate: null,
    nowShowing: [],
    cinemas: [],
    city: localStorage.getItem('selectedcity'),
    cities: [],
    copytoself: false


  }
  componentDidMount() {
    let formDataNS = new FormData();    //formdata object
    formDataNS.append('city', this.state.city);   //append the values with key, value pair
    formDataNS.append('av', Constants.av);
    formDataNS.append('pt', Constants.pt);
    formDataNS.append('did', Constants.did);
    formDataNS.append('userid', localStorage.getItem('userid'));
    formDataNS.append('dtmsource', 'local');
    formDataNS.append('mobile', '');

    const nowShowingRequest = async () => {
      try {
        const resp = await axios.post(Constants.api + '/api/content/nowshowing', formDataNS).then(resp => {
          //  casetype array   
          if (resp.data.result == 'success') {
            this.setState(
              {
                loading: 0
              }
            );
            console.log(resp.data.output);
            let nowShowingArray = []
            Object.keys(resp.data.output).forEach(function (key) {
              nowShowingArray.push(resp.data.output[key])
            });

            this.setState({
              nowShowing: nowShowingArray,
            })
            console.log('hello');
            console.log(this.state.nowShowing);
          } else {
            console.log(resp.data.msg);
            this.setState(
              {
                loading: 0
              }
            );
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
        this.setState(
          {
            loading: 1
          }
        );
      }
    };
    const sendCityRequest = async () => {
      try {
        const resp = await axios.post(Constants.api + '/api/content/cities', formData).then(resp => {
          //  casetype array   
          if (resp.data.result == 'success') {
            this.setState(
              {
                loading: 0
              }
            );


            let citiesArray = []
            Object.keys(resp.data.output.ot).forEach(function (key) {
              citiesArray.push(resp.data.output.ot[key])
            });

            this.setState({
              cities: citiesArray,
            })
            console.log(this.state.cities);
          } else {
            console.log(resp.data.msg);
            this.setState(
              {
                loading: 1
              }
            );
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    if (!localStorage.getItem('userid'))
      localStorage.setItem('userid', 0);
    let formData = new FormData();    //formdata object
    formData.append('searchtxt', '');   //append the values with key, value pair
    formData.append('av', Constants.av);
    formData.append('pt', Constants.pt);
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('lat', '0.0');
    formData.append('lng', '0.0');
    formData.append('city', this.state.city);
    const sendCinemaRequest = async () => {

      try {
        const resp = await axios.post(Constants.api + '/api/content/alltheater', formData).then(resp => {
          //  casetype array   

          if (resp.data.result == 'success') {
            this.setState(
              {
                loading: 0
              }
            );

            this.setState({ mapUrl: 'https://www.google.com/maps/embed/v1/place?q=' + resp.data.output.c[0].lat + ',' + resp.data.output.c[0].lang + '&key=AIzaSyCar-wmGPP_TMi_Phi9HoIosnGf3N8ioQ8' })

            let cinemasArray = []
            Object.keys(resp.data.output.c).forEach(function (key) {
              cinemasArray.push(resp.data.output.c[key])
            });

            this.setState({
              cinemas: cinemasArray,
            })
            console.log(this.state.cinemas);
          } else {
            console.log(resp.data.msg);
            this.setState(
              {
                loading: 1
              }
            );
          }
          //movie array


        });

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    sendCinemaRequest();
    // if (!sessionStorage.getItem('selectedcity'))
    sendCityRequest();
    nowShowingRequest();

  }
  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let feedbackdata = new FormData();    //feedbackdata object
      feedbackdata.append('av', Constants.av);
      feedbackdata.append('platform', Constants.pt);
      feedbackdata.append('did', Constants.did);
      feedbackdata.append('name', this.state.name);
      feedbackdata.append('mname', this.state.movie);
      feedbackdata.append('seats', this.state.seats);
      feedbackdata.append('cname', this.state.cinema);
      feedbackdata.append('city', this.state.city);
      feedbackdata.append('mobile', this.state.mobile);
      feedbackdata.append('email', this.state.email);
      feedbackdata.append('type', this.state.type);
      feedbackdata.append('comments', this.state.comment);
      feedbackdata.append('bookingdate',this.state.bd);
      feedbackdata.append('copySelf', this.state.copytoself);
      feedbackdata.append('resend', this.state.resend);
      feedbackdata.append('otp', this.state.otp);

      axios.post(Constants.api + '/api/bulkbooking', feedbackdata)
        .then(res => {
          console.log(res);
          if (res.data.result == 'dialog') {
            // swal("Your Enquiry has been Succesfully Submitted. ","We Will Get Back to you Soon.","success");
            // document.getElementById("feedbackform").reset();
            swal("", "Please enter OTP received on your Mobile Number", "info");
         
              this.setState({ otpshow: 1 });
            
            //window.location.reload(false);
          }
          else {
            // this.setState({alert:res.data.msg});
            swal(res.data.msg);
            window.location.reload(false);
          }


        })
    }
    else
      this.validator.showMessages();
    this.forceUpdate();
  }
  namehandleChange = event => {
    this.setState({ name: event.target.value });

  }
  emailhandleChange = event => {
    this.setState({ email: event.target.value });

  }
  mobilehandleChange = event => {
    this.setState({ mobile: event.target.value });

  }
  namehandleChange = event => {
    this.setState({ name: event.target.value });

  }
  otphandleChange = event => {
    this.setState({ otp: event.target.value });

  }
  emailhandleChange = event => {
    this.setState({ email: event.target.value });

  }
  commenthandleChange = event => {
    this.setState({ comment: event.target.value });

  }
  handleCheckboxChange = event =>
    this.setState({ checked: event.target.checked, copytoself: event.target.value })

  bookingdatehandleChange = date => {

    
      var dates= new Date(date),
        mnth = ("0" + (dates.getMonth() + 1)).slice(-2),
        day = ("0" + dates.getDate()).slice(-2);
      var bd = [dates.getFullYear(), mnth, day].join("-");
     
      this.setState({
        bookingdate: date,
        bd: bd,
      });
     
    
  };


  movieChange = event => {
    this.setState({ 'movie': event.target.value });

  }
  otpChange = event => {
    this.setState({ 'otp': event.target.value });

  }
  resendChange = event => {
    this.setState({ 'resend':true });
    this.handleSubmit();

  }
  cinemaChange = event => {
    this.setState({ 'cinema': event.target.value });

  }
  seatsChange = event => {
    this.setState({ 'seats': event.target.value });

  }
  render() {
    const yesterday = moment().subtract(1, 'day');
    const disablePastDt = current => {
      return current.isAfter(yesterday);
    };
    if (this.state.loading == 1) {
      return (
        <>
          <Loading />
        </>
      )
    }
    const citiesDropdownRender = this.state.cities.map(function (item, i) {
      // alert(item.mname);


      return (
        <>

          <option value={item.name} key={i}  >
            {item.name}
          </option>
        </>
      )
    });
    const nowshowingRender = this.state.nowShowing.map(function (item, i) {
      // alert(item.mname);


      return (
        <>

          <option value={item.n} key={i} >
            {item.n}
          </option>
        </>
      )
    });
    const cinemaRender = this.state.cinemas.map(function (item, i) {
      // alert(item.mname);


      return (
        <>

          <option value={item.n} key={i}  >
            {item.n}
          </option>
        </>
      )
    });


    return (
      <>
        <Header />
        <>
          <section class="_header_banner__">
            <div class="">
              <div class="row">
                <div class="movie_poster_sesion_banner">
                  <div class="_poster_banner">
                    <img class="img-responsive" src={require('../assets/about_banner.jpg').default} style={{ width: "100%" }} alt="" />
                  </div>
                  <div class="_pos_abs_poster">
                    <div class="grid__">
                      {/* <div class="_movie_details_">
                            <div class="_modal_title_ text-uppercase">about us</div>
                          </div> */}
                    </div>
                    <div class="grid__ text-right _pos_rel">

                      <ul class="breadcarum">
                        <li><a href="/">Home</a></li>
                        <li class="links_back">></li>
                        <li class="links_back">Bulk Booking</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="feedback_section">
            <div class="container">
              <div class="row">
                <div class="col-sm-8 col-sm-offset-2 col-xs-12">
                  <div class="feedback-box">
                    <form onSubmit={this.handleSubmit} id="feedbackform" class="feedback_form">
                      <div class="col-sm-12 col-xs-12"><h4 class="_title_feed_back">Bulk Booking</h4></div>
                      <div class="col-sm-6 col-xs-12">
                        <div class="form-group">
                          <input type="text" class="feed_input" value={this.state.value} onChange={this.namehandleChange} name="name" placeholder="Name" />
                        </div>
                        {this.validator.message('name', this.state.name, 'required')}
                      </div>

                      <div class="col-sm-6 col-xs-12">

                        <div class="select-cutom _icon_set form-group">
                          <select class="form-control _icon_set" name="movie" value={this.state.value} onChange={this.movieChange} placeholder="Movie">
                            <option value="">Select Movie</option>
                            {nowshowingRender}
                          </select>

                          <i class="fa fa-film _set_abs _clr_red" aria-hidden="true"></i>
                        </div>

                      </div>
                      {this.validator.message('movie', this.state.movie, 'required')}
                      <div class="col-sm-6 col-xs-12">

                        <div class="select-cutom _icon_set form-group">
                          <select class="form-control _icon_set" name="cinema" value={this.state.value} onChange={this.cinemaChange} placeholder="Cinema">
                            <option value="">Select Cinema</option>
                            {cinemaRender}
                          </select>
                          <i class="fa fa-desktop _set_abs _clr_red" aria-hidden="true"></i>

                        </div>
                      </div>
                      {this.validator.message('cinema', this.state.cinema, 'required')}



                      <div class="col-sm-6 col-xs-12">
                        <div class="form-group">
                          <input type="text" class="feed_input" name="mobile" value={this.state.value} onChange={this.mobilehandleChange} placeholder="Mobile" />
                        </div>
                        {this.validator.message('mobile', this.state.mobile, 'required|min:10|max:10')}
                      </div>

                      <div class="col-sm-6 col-xs-12">
                        <div class="form-group">
                          <input type="text" class="feed_input" value={this.state.value} onChange={this.emailhandleChange} name="email" placeholder="Email" />
                        </div>
                        {this.validator.message('email', this.state.email, 'required|email')}
                      </div>
                      <div class="col-sm-6 col-xs-12">
                        <div class="form-group">
                          <input type="number" class="feed_input" value={this.state.value} onChange={this.seatsChange} name="seats" placeholder="Seats required" />
                        </div>
                        {this.validator.message('seats', this.state.seats, 'required|numeric')}
                      </div>

                      <div class="col-sm-6 col-xs-12"></div>

                      <div class="col-sm-12 col-xs-12">
                        <div class="form-group">
                          <textarea class="text-area" maxlength="500" rows="4" name="comment" value={this.state.value} onChange={this.commenthandleChange} type="text" placeholder="Message"></textarea>
                        </div>
                        {this.validator.message('comment', this.state.comment, 'required')}
                      </div>

                      <div class="col-sm-8 col-xs-12" style={{ paddingLeft: "0" }}>

                        <div class="col-sm-12 col-xs-6">
                          <DatePicker
                            name='bookindgate'
                            isValidDate={disablePastDt}
                            selected={this.state.bookingdate}
                            
                            shouldCloseOnSelect={true}
                            onChange={this.bookingdatehandleChange}
                            placeholderText=" Booking Date"
                            class="form-group required"
                            autoComplete="off"
                            />
                        </div>
                      </div>
                      {this.validator.message('bookingdate', this.state.bookingdate, 'required')}
                      <div class="_type_check__">

                        <div class="col-sm-8 col-xs-6">
                          <input type="checkbox" id="movie1" name="copytoself" onChange={this.handleCheckboxChange} value="true" />
                          <label for="movie1"> Copy to Self</label>
                        </div>





                      </div>


                      <div class="col-sm-12 col-xs-12">
                        <div class="form-group _send_request">
                          <button class="btn btn_blue btn_text" type="submit">SEND Enquiry</button>
                        </div>
                      </div>

                      <div class="col-sm-6 col-xs-12">
                        {/* <div class="form-group">
                      <span class="phone_feed">Ph: 011 - 2323XXXX</span>  
                    </div> */}
                      </div>

                      <div class="col-sm-6 col-xs-12">
                        <div class="form-group">
                          <a href="mailto:info@movietimecinemas.in"> <span class="email_feed">Email: info@movietimecinemas.in
                            </span></a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <Modal class="body_otp" show={this.state.otpshow} animation={false} class="over_lay_login"
          backdrop="static">
          <Modal.Body>
            <div>

              <div class="login_header">
                <p class="text_color">We sent an sms with confirmation code to your mobile number</p>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div class="form-group">
                  <input type="text" class="form-control" name="otp" onChange={this.otphandleChange} value={this.state.value} placeholder="Enter your OTP" />
                  <div class="Invalid_num">
                    {this.otpvalidator.message('otp', this.state.otp, 'required|min:6|max:6|numeric')}</div>

                </div>

                {/* <a href="#" onClick={this.resendChange} class="forgot_pass">Resend OTP</a> */}


                <div class="form-group">
                  <button class="btn btn_facebook" type="submit">Submit OTP</button>
                </div>
              </form>



            </div>
          </Modal.Body>
        </Modal>
   
        </>

        <Footer />
        </>
    );
  }
}

export default BulkBooking;
